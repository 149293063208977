import { Button, FilledInput, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from "@mui/material";
import { StyledLogin } from "./login.styled";
import { useState } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import { UserAddSchema } from '../../models/user';
import { z } from "zod";
import Link from '@mui/material/Link';

const loginSchema = UserAddSchema.pick({
    email: true,
    password: true
}).required();

type LoginModel = z.infer<typeof loginSchema>



function Login() {

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const { user, loginUser } = useAuth();

    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<LoginModel>({
        resolver: zodResolver(loginSchema),
    });


    const onSubmit: SubmitHandler<LoginModel> = async (data) => {

        await loginUser(data.email, data.password);

        if (user) {
            navigate('/');
        }
    };


    return (
        <StyledLogin>
            <Grid container sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <Grid item xs={11} md={9} lg={7} sx={{ flexGrow: 1, display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", alignItems: "center", gap: 3 }} className="page">
                    <Typography variant="h3">התחברות</Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <FormControl sx={{ m: 1, width: '250px' }} variant="filled">
                            <TextField id="email" label="אימייל"
                                error={errors?.email ? true : false}
                                variant="filled"
                                sx={{ width: '250px' }}
                                helperText={errors?.email?.message}
                                {...register("email")} />
                        </FormControl>


                        <FormControl sx={{ m: 1, width: '250px' }} variant="filled" error={errors?.password ? true : false}>
                            <InputLabel htmlFor="filled-adornment-password">סיסמה</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                {...register("password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((show) => !show)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{errors?.password?.message}</FormHelperText>
                        </FormControl>
                        <Button variant="contained" type="submit" disabled={isSubmitting} >התחבר</Button>
                        <Link onClick={()=>{navigate("/register")}} className="register-link" sx={{textDecoration: "none"}}>הרשמה</Link>
                    </form>
                </Grid>
            </Grid>
        </StyledLogin>
    );
}

export default Login;