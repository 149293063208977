import { z } from "zod";
import { WorkCustomDataSchema } from "./work-custom-data";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);


export const WorkSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    work_code: z.string(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),  


    // nullable
    name: z.string().nullable(),
    info: z.string().nullable(),
    price: z.number().nullable(),
    hours: z.number().nullable(),
    manufacturer: z.number().nullable(),
    model: z.number().nullable(),
    income_card: z.number().nullable(),

    //loads
    custom_data: z.array(WorkCustomDataSchema).nullish(),
});



export const WorkEditSchema = z.object({
    
    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),


    //loads
    custom_data: z.array(WorkCustomDataSchema).nullish(),
});


export const WorkAddSchema = z.object({

    // required fields
    work_code: z.string().min(1),


    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),


    //loads
    custom_data: z.array(WorkCustomDataSchema).nullish(),
});


export const WorkFormSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),


    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
        
    
    // required fields
    work_code: z.string().min(1),


    // optional nullable fields
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),


    //loads
    custom_data: z.array(WorkCustomDataSchema).nullish(),
});



export const WorkSearchSchema = z.object({

    //inputs required
    work_code: z.string().nullish(),

    //inputs optional
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    custom_data: z.array(WorkCustomDataSchema).optional(),
});


export type Work = z.infer<typeof WorkSchema>
export type WorkEdit = z.infer<typeof WorkEditSchema>
export type WorkAdd = z.infer<typeof WorkAddSchema>
export type WorkForm = z.infer<typeof WorkFormSchema>
export type WorkSearch = z.infer<typeof WorkSearchSchema>
