import axiosClient from "../axios-client";
import { Work, WorkAdd, WorkEdit, WorkSchema } from "../models/work";

export const getWorks = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[] = [], body: object = {}, signal: AbortSignal): Promise<Work[]> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/works/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}

export const getWorkById = async (id: string, signal?: AbortSignal): Promise<Work> => {
    const response = await axiosClient.get(`/api/works/${id}`, {
        signal: signal // Pass the signal to the request options
    });

    return response.data
}

export const getNextWorkNumber = async () : Promise<number> => {
    const response = await axiosClient.get(`/api/works/next-number`);
    return response.data.next_work_index as number;
}

export const addWork = async (workToAdd: WorkAdd) : Promise<Work> => {
    const response = await axiosClient.post(`/api/works`, workToAdd);
    return response.data;
}

export const deleteWork = async (workId: number): Promise<void> => {
    await axiosClient.delete(`/api/works/${workId}`);
}

export const saveWork = async (workId: number, workToSave: WorkEdit): Promise<Work> => {
    const response = await axiosClient.patch(`/api/works/${workId}`, workToSave);
    return WorkSchema.parse(response.data);
}
