import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentStructureShowAddSchema, DocumentStructureShowSchema } from "./document-structure-show";

z.setErrorMap(customErrorMap);

export const DocumentStructureSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    type: z.string(),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string(),
    field_heb_name: z.string(),
    field_display_name: z.string(),
    field_length: z.number(),
    field_type: z.number(),
	to_print: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),


    // nullable
    field_table: z.number().nullable(),
    custom_field: z.number().nullable(),
    location: z.number().nullable(),



    //loads
    show_form_array: z.array(DocumentStructureShowSchema),
    
    //client side
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().optional()),

    
});

export const DocumentStructureEditSchema = z.object({

    id: z.number(),
    business_id: z.number(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),

    // required
    type: z.string().min(1),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string().min(1),
    field_heb_name: z.string().min(1),

    // nullable
    custom_field: z.number().nullable(),


    // required fields
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()),
    field_length: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    field_type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    to_print: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),


    // optional nullable fields
    field_table: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullable()),
    location: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullable()),

    // loads
    show_form_array: z.array(DocumentStructureShowAddSchema),

    // client side
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

});

export const DocumentStructureAddSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    // required
    type: z.string().min(1),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string().min(1),
    field_heb_name: z.string().min(1),

    // nullable
    custom_field: z.number().nullable(),


    // required fields
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()),
    field_length: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    field_type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    to_print: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),


    // optional nullable fields
    field_table: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    location: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    // // loads
    // show_form_array: z.array(DocumentStructureShowFormSchema).optional(),

    // client side
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().optional()),

});

export type DocumentStructure = z.infer<typeof DocumentStructureSchema>
export type DocumentStructureEdit = z.infer<typeof DocumentStructureEditSchema>
export type DocumentStructureAdd = z.infer<typeof DocumentStructureAddSchema>
