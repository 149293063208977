import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { StyledEditCashBox } from "./EditCashBox.styled";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Account, AccountSchema } from "../../../../models/account";
import { deleteEmptyFieldsFromObject, groupBy } from "../../../../helpers/helpers";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setCashBoxesStructure } from "../../../../redux/features/structure";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { handleApiErrors, toastErrorHandling } from "../../../../services/errorHandling";
import { deleteAccount, saveAccount } from "../../../../services/accountsService";
import { AccountContext } from "../../../../utils/AccountContext";
import AlertDialog from "../../../../components/alert-dialog/AlertDialog";
import { setCustomDataArray } from "../../../../services/customDataService";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from "@emotion/react";
import { useErrorBoundary } from "react-error-boundary";

function EditCashBox() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const theme = useTheme();
  const { showBoundary } = useErrorBoundary();
  

  const cashBoxStructure = useSelector((state: RootState) => state.structure.cashBoxes);
  const structureSortedByTitle = groupBy(cashBoxStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure => !!structure.show_form === true);

  const { account, setAccount } = useContext(AccountContext);


  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const form = useForm<Account>({
    resolver: zodResolver(AccountSchema),
    defaultValues: {
      ...deleteEmptyFieldsFromObject(account)
    }
  });


  useEffect(() => {
    if (!cashBoxStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);

  useEffect(() => {
    if (account) {
      form.reset(account);
    }
  }, [account]);


  async function getStructureAndSaveAtStore() {
    try {

      const structure = await getAccountsStructure({
        account_type: "קופה"
      });

      dispatch(setCashBoxesStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
  }

  const saveTicket = async () => {

    try {

      form.setValue("account_type", "קופה");

      const customDataArray = form.getValues("custom_data");
      setCustomDataArray(customDataArray);
      form.setValue("custom_data", customDataArray);

      form.handleSubmit(onSubmit)();


    } catch (error) {
      console.error("Form submission failed:", error);
    }

  }

  const onSubmit: SubmitHandler<Account> = (data) => {

    setAccount(data);

    // const dataToSend = deleteEmptyFieldsFromObject(data);

    const savePromise = async () => {
      await saveAccount(data.id!, data);
    }


    toast.promise(
      savePromise,
      {
        pending: 'מעדכן כרטיס...',
        success: 'הכרטיס עודכן בהצלחה',
        error: {
          render({ data }) {
            return toastErrorHandling(data)
          }
        }
      }
    )
  };

  function returnFunc() {
    window.history.back();
  }

  const onDelete = async () => {
    const deletePromise = async () => {
      if (account) {
        await deleteAccount(account.id!);
        window.history.back();
      }
    }

    toast.promise(
      deletePromise,
      {
        pending: 'מוחק כרטיס...',
        success: 'הכרטיס נמחק בהצלחה',
        error: {
          render({ data }) {
            return toastErrorHandling(data)
          }
        }
      }
    )
  };

  function setStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'form',
        structureName: "קופה",
        structureType: 'account',
        accountType: "cash-boxes"
      }
    });
  }

  return (
    <StyledEditCashBox>
      {!cashBoxStructure ? <Skeleton variant="rectangular" height={500} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} /> :
        <>
          <div className="form">
            <div><div className="left">
              {/* <button onClick={setStructure}>הגדרת שדות</button> */}
              <Tooltip title="הגדרת שדות">
                <IconButton onClick={setStructure} sx={{ color: theme.colors.main_color }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div></div>

            <div>
              <Typography variant="body1">פרטים:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["פרטים"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
              <div className="fields">
                {structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

          </div>

          <div className="buttons">
            <div className="right">
              <Button variant="contained" onClick={saveTicket} disabled={form.formState.isSubmitting} >שמירה</Button>
              <Button variant="outlined" onClick={returnFunc}>חזור</Button>
              <Button variant="outlined" color="error" onClick={() => setDialogIsOpen(true)} disabled={account?.entries?.length ? true : false} >מחיקה</Button>
            </div>
            <div className="left">
            </div>
          </div>

        </>
      }
      <AlertDialog
        open={dialogIsOpen}
        handleClose={() => { setDialogIsOpen(false) }}
        handleConfirm={onDelete}
        title="אישור מחיקה"
        message="אתה בטוח שאתה רוצה למחוק כרטיס זה?"
        confirm="מחיקה"
      />
    </StyledEditCashBox>
  )
}


export default EditCashBox