import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentStructureShowSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    document_settings_id: z.number(),
    document_structure_id: z.number(),
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
});

export const DocumentStructureShowAddSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    // required
    document_settings_id: z.number(),
    document_structure_id: z.number(),

    // required fields
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

});

export type DocumentStructureShow = z.infer<typeof DocumentStructureShowSchema>
