import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form"
import { CssField } from "../../../../../components/mui-input/MuiInput.styled";
import { InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { DocumentAdd } from "../../../../../models/document";
import { DocumentDepositAdd } from "../../../../../models/document-deposit";
import { useEffect, useState } from "react";
import { Account } from "../../../../../models/account";
import SelectDeposits from "../select-deposits/SelectDeposits";
import { getAccountById } from "../../../../../services/accountsService";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../../../services/errorHandling";

interface DepositProps {

    documentForm: UseFormReturn<DocumentAdd>;
    documentDepositsForm: UseFormReturn<{ deposits: DocumentDepositAdd[] }>;
    depositsFieldArray: UseFieldArrayReturn<{ deposits: DocumentDepositAdd[] }, "deposits", "id">;
    kupaAccounts: Account[];


    isSelectDepositsDialogOpen: boolean;
    isSelectCashDialogOpen: boolean;

    setIsSelectDepositsDialogOpen: (IsSelectDepositsDialogOpen: boolean) => void;
    setIsSelectCashDialogOpen: (IsSelectCashDialogOpen: boolean) => void;
}

function Deposit({ documentForm, documentDepositsForm, depositsFieldArray, kupaAccounts, isSelectDepositsDialogOpen, setIsSelectDepositsDialogOpen }: DepositProps) {


    const [depositType, setDepositType] = useState<string>("הפקדה רגילה");
    const [kupaAccountsSelect, setKupaAccountsSelect] = useState<Account[]>();
    const [kupaAccountId, setKupaAccountId] = useState<number | undefined>(kupaAccounts.find(account => account.payment_type === "שיקים" || account.payment_type === "אשראי")?.id);
    const [cashKupaAccount, setCashKupaAccount] = useState<Account>();
    const [totalKupaCash, setTotalKupaCash] = useState<number>();
    const [cashSelected, setCashSelected] = useState<number>();
    const { showBoundary } = useErrorBoundary();


    // when Afkada mothod change: set the accounts list in the select, and set defualt kupaAccountId.
    useEffect(() => {

        documentDepositsForm.reset({ deposits: [] });


        if (depositType === "הפקדה רגילה" || depositType === "הפקדה דחויה" || depositType === "הפקדת מזומן") {
            const accounts = kupaAccounts.filter(account => account.payment_type === "שיקים" || account.payment_type === "אשראי");
            setKupaAccountsSelect(accounts);
            setKupaAccountId(accounts[0]?.id);
        }

        if (depositType === "הפקדת מזומן") {
            const accounts = kupaAccounts.filter(account => account.payment_type === "מזומן");
            setKupaAccountsSelect(accounts);
            setKupaAccountId(accounts[0]?.id);

        }

    }, [depositType]);


    // when kupaAccountId change and it is cash: get Kupa account with entries
    useEffect(() => {

        const getCashAccount = async () => {

            if (kupaAccountId && depositType === "הפקדת מזומן") {
                try {
                    const account: Account = await getAccountById(kupaAccountId);
                    setCashKupaAccount(account);
                } catch (error) {
                    handleApiErrors(error, showBoundary);;
                }
            }
        }

        getCashAccount();

    }, [kupaAccountId]);


    // calc the total cash of Cash Account
    useEffect(() => {

        if (cashKupaAccount && cashKupaAccount.entries) {
            const totalCash: number = cashKupaAccount.entries.reduce((sum, entry) => sum + ((entry.debit || 0) - (entry.credit || 0)), 0);
            setTotalKupaCash(totalCash);
        }

    }, [cashKupaAccount]);




    return (
        <div className="deposit">
            <div className="title">
                <div>
                    <Typography variant="body1" component="span">
                        הפקדה:
                    </Typography>
                </div>
            </div>
            <div>

                <CssField>
                    <InputLabel>סוג הפקדה</InputLabel>
                    <Select
                        value={depositType}
                        onChange={(e) => setDepositType(e.target.value)}
                        id={"deposit_Type"}
                    >
                        <MenuItem value="הפקדה רגילה">הפקדה רגילה</MenuItem>
                        <MenuItem value="הפקדה דחויה">הפקדה דחויה</MenuItem>
                        <MenuItem value="הפקדת מזומן">הפקדת מזומן</MenuItem>
                        <MenuItem value="נכיון שיקים">נכיון שיקים</MenuItem>
                    </Select>
                </CssField>

                <CssField>
                    <InputLabel>קופה</InputLabel>
                    <Select
                        value={kupaAccountId}
                        onChange={(e) => setKupaAccountId(Number(e.target.value))}
                        id={"kupa_Account"}
                    >
                        {
                            kupaAccountsSelect?.map((kupaAccount, index) => (
                                <MenuItem key={index} value={kupaAccount.id}>{kupaAccount.account_name}</MenuItem>
                            ))
                        }
                    </Select>
                </CssField>


            </div>
            {depositType !== "הפקדת מזומן" && (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>סכום</th>
                                <th>שם קופה</th>
                                <th>מס חשבון</th>
                                <th>סניף</th>
                                <th>שם בנק</th>
                                <th>מס בנק</th>
                                <th>מס שיק</th>
                                <th>לתאריך</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                depositsFieldArray.fields.map((deposit, rowIndex) => {

                                    return (
                                        <tr key={rowIndex}>
                                            <td>{rowIndex + 1}.</td>
                                            <td>{deposit.total}₪</td>
                                            <td>{deposit.kupa_account?.account_name}</td>
                                            <td>{deposit.bank_account_number}</td>
                                            <td>{deposit.bank_branch_number}</td>
                                            <td>{deposit.bank_name}</td>
                                            <td>{deposit.bank_number}</td>
                                            <td>{deposit.check_number}</td>
                                            <td>{deposit.to_date}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <button onClick={() => { setIsSelectDepositsDialogOpen(true) }} disabled={!documentForm.getValues('customer.account_number')}>בחירת שיקים להפקדה</button>
                </>
            )}

            {depositType === "הפקדת מזומן" && (

                <div>
                    <CssField>
                        <TextField
                            type="number"
                            value={cashSelected}
                            onChange={(e) => setCashSelected(Number(e.target.value))}
                            InputProps={{ startAdornment: <InputAdornment position="start">₪</InputAdornment> }}
                            label="סכום במזומן"
                            sx={{ direction: 'rtl' }}

                        />
                    </CssField>
                    <div>
                        סכום המזומן בקופה: {totalKupaCash}
                    </div>
                </div>
            )}

            <SelectDeposits
                open={isSelectDepositsDialogOpen}
                onClose={(selectedDeposits?: DocumentDepositAdd[], despositsSum?: number) => {

                    setIsSelectDepositsDialogOpen(false);

                    if (selectedDeposits) {
                        console.log("if selectedDeposits");
                        documentDepositsForm.reset({ deposits: selectedDeposits });
                    }

                    if (despositsSum) {
                        documentForm.setValue("deposit", despositsSum);
                    }

                }}
            />
        </div>
    )
}

export default Deposit