import styled from '@emotion/styled'

export const StyledRegister = styled.div`

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .section{
        display: flex;
        gap: 15px;
        margin: 20px;
    }


    .login-link{
        cursor: pointer;
        margin-top: 30px;
    }
`