import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { getAllModels, getSystemTableByNumber, getSystemTableNameFromStore } from "../../services/tablesService";
import { useEffect } from "react";
import { setBanksTable, setItemGroupsTable, setManufacturersTable, setModelsTable, setSortCodesTable, setTagsTable, setWarehousesTable } from "../../redux/features/tables";
import { Table } from "../../models/table";
import { Account } from "../../models/account";
import { Document } from "../../models/document";
import { DocumentSettings } from "../../models/document-settings";
import { dateToString, getValueByPath } from "../../helpers/helpers";
import { Item } from "../../models/item";
import { Work } from "../../models/work";
import { Car } from "../../models/car";
import dayjs from "dayjs";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../services/errorHandling";

type FieldStructure = {
    field_name: string,
    field_type: number,
    field_table: number | null
}

interface PrintFieldValueProps<T extends FieldStructure> {
    field: T;
    //value: string | number | boolean | Date | AccountCustomData[] | undefined;
    accountData: Account | Document | Item | Work | Car;
}


function PrintFieldValue<T extends FieldStructure>({ field, accountData }: PrintFieldValueProps<T>) {

    const dispatch = useDispatch();
    const { showBoundary } = useErrorBoundary();
    
    const tables = useSelector((state: RootState) => state.tables);
    
    let value = getValueByPath(accountData, field.field_name)
        // || (accountData as Account).custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        // || (accountData as Document).customer?.custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        // || (accountData as Document).car?.custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        || (accountData as Document).document_settings?.[field.field_name.split(".")[1] as keyof DocumentSettings]
        || (accountData as Document).account?.[field.field_name.split(".")[1] as keyof Account];

    value = addShekelSymbol(value);

    function addShekelSymbol(value: any){
        const priceFieldsNames = ["price", "total", "net", "cost"];
        return priceFieldsNames.includes(field.field_name)? `${value}₪` : value;
    }

    

    useEffect(() => {
        if (field.field_type === 4) { //select
            const key = getSystemTableNameFromStore(field.field_table);

            if (key) {
                const table = tables[key];
                if (!table) {
                    getTableAndSaveAtStore(field.field_table);
                }
            }
        }

        if (field.field_type === 6) { //model

            if(!tables.manufacturersTable){
                getTableAndSaveAtStore(1);
            }

            if(!tables.modelsTable){
                getModelsAndSaveAtStore();
            }

        }

    }, []);

    const getTableAndSaveAtStore = async (tableNumber: number | null) => {
        
        try {
            if(tableNumber){
                const table = await getSystemTableByNumber(tableNumber);
                dispatchSystemTable(tableNumber, table);
            }
        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }

    const getModelsAndSaveAtStore = async () => {
        try {
            const table = await getAllModels();
            dispatch(setModelsTable(table));
        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }

    const dispatchSystemTable = (tableNumber: number, table: Table[] = []) => {
        switch (tableNumber) {
            case 1:
                dispatch(setManufacturersTable(table));
                break;

            case 2:
                dispatch(setWarehousesTable(table));
                break;

            case 3:
                dispatch(setTagsTable(table));
                break;

            case 4:
                dispatch(setSortCodesTable(table));
                break;

            case 5:
                dispatch(setItemGroupsTable(table));
                break;

            case 6:
                dispatch(setBanksTable(table));
                break;

            default:
                break;
        }
    }


    if (field.field_type === 1 || field.field_type === 2) { // en/heb
        return <>{value}</>
    }

    if (field.field_type === 3) { //date
        // const date = new Date(value as string);
        const date = dayjs(value as string);
        return <>{dateToString(date.toDate())}</>
    }

    if (field.field_type === 4) { //select
        const key = getSystemTableNameFromStore(field.field_table);

        if (key) {
            const table = tables[key];
            const textValue = table?.find(item => value === item.index)?.name;
            return textValue ? <>{textValue}</> : <></>;
        }

        else {
            return <></>;
        }

    }

    if (field.field_type === 5) { //select
        return <>{value}</>
    }

    if (field.field_type === 6) { //model

        const manufacturer_index = (accountData as Car).manufacturer || (accountData as Document).car?.manufacturer;
        const model_index = (accountData as Car).model || (accountData as Document).car?.model;

            if(manufacturer_index && model_index){

                const manufacturer_id = tables.manufacturersTable?.[manufacturer_index-1].id;
                const models = tables.modelsTable;
    
    
                const textValue = models?.filter(model => model.manufacturer_id === manufacturer_id)[model_index-1]?.name;
                return textValue ? <>{textValue}</> : <></>;
              
            }

            else{
                return <></>;
            }

    }

    if (field.field_type === 7) { //number
        return <>{value}</>
    }


    if (field.field_type === 8) { //date
        return <>{(value as number)?.toFixed(2)}</>
    }

    if (field.field_type === 21) { //number
        return <>{value ? "כן" : "לא"}</>
    }

    return (
        <>PrintInputValue</>
    )
}

export default PrintFieldValue