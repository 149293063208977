import styled from '@emotion/styled'

export const StyledSelectInvoices = styled.div`

    min-height: 80vh;
    min-width: 25vw;

    h2{
        text-align: center;
    }

    th{
        text-align: left;
    }

    .details{
        display: flex;
        flex-direction: column;
    }

    .buttons{
        display: flex;
        justify-content: center;
        gap: 25px;
    }

`