import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentCarCustomDataSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    document_car_id: z.number(),
    field_index: z.number(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),


    // nullable
    field_value: z.string().nullable(),

});

export const DocumentCarCustomDataFormSchema = z.object({

    // optional in create
    id: z.number().optional(),
    business_id: z.number().optional(),
    document_car_id: z.number().optional(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    // required
    field_index: z.number(),

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

});

export type DocumentCarCustomData = z.infer<typeof DocumentCarCustomDataSchema>
export type DocumentCarCustomDataForm = z.infer<typeof DocumentCarCustomDataFormSchema>