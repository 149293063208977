import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentClosingSchema = z.object({

    // required
    id: z.number(), // id.
    business_id: z.number(), // business object id.
    document_closing_id: z.number(), // document object id that closing.
    document_closed_id: z.number(), // document object id that being closed.
    status: z.string(), // the new document status after paid.
    reference: z.string(), // reference name to the original document being closed.
    amount: z.number(), // the document amount that need to pay.
    balance: z.number(), // the amount remains after paid.

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),

})


export const DocumentClosingFormSchema = z.object({

    // optional in create
    id: z.number().optional(), // id.
    business_id: z.number().optional(), // business object id.
    document_closing_id: z.number().optional(), // document object id that closing.

    document_closed_id: z.number(), // document object id that being closed.
    name: z.string(), // document name and number
    status: z.preprocess((val) => myPreProcess(val), z.enum(["פתוח", "סגור", "סגור חלקית"])), // the new document status after paid.
    reference: z.string(), // reference name to the original document being closed.
    amount: z.number(), // the document amount that need to pay.
    balance: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // the amount remains after paid.

    // client side
    old_status: z.string(), // old document status
    old_balance: z.number(), // old document balance

});


export type DocumentClosing = z.infer<typeof DocumentClosingSchema>
export type DocumentClosingForm = z.infer<typeof DocumentClosingFormSchema>
