import axiosClient from "../axios-client";
import { Item, ItemAdd, ItemEdit } from "../models/item";

export const getItems = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[] = [], body: object = {}, signal: AbortSignal): Promise<Item[]> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/items/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}

export const getItemById = async (id: string, signal?: AbortSignal): Promise<Item> => {
    const response = await axiosClient.get(`/api/items/${id}`, {
        signal: signal // Pass the signal to the request options
    });

    return response.data
}

export const saveItem = async (itemId: number, itemToSave: ItemEdit): Promise<Item> => {
    const response = await axiosClient.patch(`/api/items/${itemId}`, itemToSave);
    return response.data;
}

export const getNextItemNumber = async (): Promise<number> => {
    const response = await axiosClient.get(`/api/items/next-number`);
    return response.data.next_item_index;
}

export const addItem = async (itemToAdd: ItemAdd): Promise<Item> => {
    const response = await axiosClient.post(`/api/items`, itemToAdd);
    return response.data;
}

export const deleteItem = async (itemId: number): Promise<void> => {
    await axiosClient.delete(`/api/items/${itemId}`);
}