
import { useEffect, useRef, useState } from 'react';
import DataTable from '../../../components/data-table/DataTable';
import { getAccountsStructure } from '../../../services/accountsStructureService';
import SearchTable from '../../../components/search-table/SearchTable';
import { StyledItems } from './items.styled';
import { deleteEmptyFieldsFromObject, setAllValuesToNull } from '../../../helpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { setItemsStructure } from '../../../redux/features/structure';
import { AccountStructure } from '../../../models/accounts-structure';
import { RootState } from '../../../redux/store';
import { Skeleton } from '@mui/material';
import { CanceledError } from 'axios';
import ListItemIcon from '@mui/material/ListItemIcon';
import MovingIcon from '@mui/icons-material/Moving';
import EditIcon from '@mui/icons-material/Edit';
import { setItemsSearch } from '../../../redux/features/browse';
import { getItems } from '../../../services/itemsService';
import { Item, ItemSchema, ItemSearch } from '../../../models/item';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from "@emotion/react";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from '../../../services/errorHandling';


interface SearchForm {
    [key: string]: string; // Index signature allowing any string key with string values
}

interface ItemsProps {
    accountTypeName: string;
    searchFormData?: SearchForm;
}

function Items({ accountTypeName, searchFormData }: ItemsProps) {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const theme = useTheme();
    const { showBoundary } = useErrorBoundary();

    const itemsStructure = useSelector((state: RootState) => state.structure.items)
    const itemsSearch = useSelector((state: RootState) => state.browse.itemsSearch)

    const [data, setData] = useState<Item[]>([]);
    const [searchStructure, setSearchStructure] = useState<AccountStructure[]>();
    const [tableStructure, setTableStructure] = useState<AccountStructure[]>();
    const [sortKey, setSortKey] = useState<string>('id');
    const [sortMethod, setSortMethod] = useState<string>('DESC');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);


    const options = [
        {
            label: "עריכה",
            icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><EditIcon fontSize="small" /></ListItemIcon>,
            href: ""
        },
        {
            label: "תנועות",
            icon: <ListItemIcon sx={{ color: theme.colors.main_color }}><MovingIcon fontSize="small" /></ListItemIcon>,
            href: "journal-entries"
        },
    ];

    const itemSearchForm = useForm<ItemSearch>({
        defaultValues: {
            ...itemsSearch,
            ...searchFormData
        }
    });

    useEffect(() => {
        if (!itemsStructure) {
            getStructureAndSaveAtStore();
        }
    }, []);

    useEffect(() => {
        setSearchStructure(itemsStructure?.filter(field => field.show_search))
        setTableStructure(itemsStructure?.filter(field => field.show_browse))
    }, [itemsStructure]);


    useEffect(() => {
        if (sortKey && sortMethod) {
            itemSearchForm.handleSubmit(onSearch)();
        }
    }, [sortKey, sortMethod]);

    useEffect(() => {
        itemSearchForm.watch(() => {
            itemSearchForm.handleSubmit(onSearch)();
        })
    }, [itemSearchForm.watch]);

    function handleSortKeyChanged(value: string) {
        setSortKey(value);
    }

    function handleSortMethodChanged(value: string) {
        setSortMethod(value);
    }

    const onSearch: SubmitHandler<ItemSearch> = (data) => {
        dispatch(setItemsSearch(data));
        getData(data);
    }


    async function getData(data: ItemSearch) {

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        setIsLoading(true);

        try {
            const responseData = await getItems(sortKey || 'id', "10", sortMethod || 'ASC', ["customData", "entries"], deleteEmptyFieldsFromObject(data), abortControllerRef.current.signal);
            const itemsParse = z.array(ItemSchema).parse(responseData);
            setData(itemsParse || []);
            setIsLoading(false);
        } catch (error: unknown) {
            if (error instanceof CanceledError) {
                return;
            }
            handleApiErrors(error, showBoundary);
        }

    }

    async function getStructureAndSaveAtStore() {
        try {

            const structure = await getAccountsStructure({
                account_type: accountTypeName
            });

            dispatch(setItemsStructure(structure || []));

        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }

    function rowChosen<T>(row: T) {
        if (location.state?.from === "/documents/add") {
            navigate(location.state?.from, {
                replace: true,
                state: {
                    ...location.state,
                    chosenItem: row
                }
            });
        }
    }

    function editSearchStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'search',
            structureName: "פריט",
            structureType: 'account',
            accountType: 'items'
        } });
    }

    function editBrowseStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'browse',
            structureName: "פריט",
            structureType: 'account',
            accountType: 'items'
        } });
    }

    function reset() {

        const values = setAllValuesToNull(itemSearchForm.getValues());

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                itemSearchForm.setValue(key as keyof ItemSearch, values[key as keyof ItemSearch]);
            }
        }
    }

    return (
        <StyledItems>
            {searchStructure ?
                <SearchTable structure={searchStructure} setStructure={editSearchStructure} reset={reset} useForm={itemSearchForm} style={{ backgroundColor: "rgb(35, 48, 68)", padding: 5, marginButton: 20 }} /> :
                <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />}
            {tableStructure ?
                <DataTable structure={tableStructure} setStructure={editBrowseStructure} sortKey={sortKey} setSortKey={handleSortKeyChanged} sortMethod={sortMethod} setSortMethod={handleSortMethodChanged} data={data} isLoading={isLoading} options={options} selectRow={location.state?.from ? true : false} onSelectRow={rowChosen} /> :
                <Skeleton variant="rectangular" height={150} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />}
        </StyledItems>
    );
}

export default Items;