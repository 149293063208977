import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { StyledEditWork } from "./EditWork.styled";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { deleteEmptyFieldsFromObject, groupBy } from "../../../../helpers/helpers";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setWorksStructure } from "../../../../redux/features/structure";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { handleApiErrors, toastErrorHandling } from "../../../../services/errorHandling";
import AlertDialog from "../../../../components/alert-dialog/AlertDialog";
import { Work, WorkEdit, WorkEditSchema } from "../../../../models/work";
import { WorkContext } from "../../../../utils/WorkContext";
import { deleteWork, saveWork } from "../../../../services/worksService";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from "@emotion/react";
import { setCustomDataArray } from "../../../../services/customDataService";
import { useErrorBoundary } from "react-error-boundary";


function EditWork() {

  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const theme = useTheme();

  const workStructure = useSelector((state: RootState) => state.structure.works);
  const structureSortedByTitle = groupBy(workStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure => !!structure.show_form === true);

  const { work, setWork } = useContext(WorkContext);


  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const form = useForm<WorkEdit>({
    resolver: zodResolver(WorkEditSchema),
    defaultValues: {
      ...deleteEmptyFieldsFromObject(work)
    }
  });


  useEffect(() => {
    if (!workStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);

  useEffect(() => {
    if (work) {
      setFormValues(work);
    }
  }, [work]);


  function setFormValues(work: Work) {
    // Object.keys(work).forEach(key => {
    //   form.setValue(key as keyof Work, work[key as keyof Work]);
    // });

    form.reset(work);
  }


  async function getStructureAndSaveAtStore() {
    try {

      const structure = await getAccountsStructure({
        account_type: "עבודה"
      });

      dispatch(setWorksStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
  }

  const saveTicket = async () => {

    try {

      // console.log("saveTicket FormValues: ", form.getValues());

      // form.setValue("card_number", ticketNumber);

      const customDataArray = form.getValues("custom_data");
      setCustomDataArray(customDataArray);
      form.setValue("custom_data", customDataArray);

      form.handleSubmit(onSubmit)();

      // console.log("errors: ", form.formState.errors);

    } catch (error) {
      console.error("Form submission failed:", error);
    }

  }

  // function filterCustomKeys<T extends object>(inputObject: T): Partial<T> {
  //   const result: Partial<T> = {};

  //   for (const key in inputObject) {
  //     if (Object.prototype.hasOwnProperty.call(inputObject, key) && key.includes("custom") && key !== "custom_data") {
  //       result[key] = inputObject[key];
  //     }
  //   }

  //   return result;
  // }

  // function returnArrayOfCustomData(formData: FormData): customData[] {

  //   const array: customData[] = Object.keys(formData).map(key => ({
  //     field_name: key,
  //     field_value: formData[key]
  //   }));

  //   return array;
  // }


  const onSubmit: SubmitHandler<WorkEdit> = (data) => {

    // setWork(data);

    // const dataToSend = deleteEmptyFieldsFromObject(data);

    const savePromise = async () => {
      const savedData = await saveWork(work!.id, data);
      setWork(savedData);

      if (location.state?.from === "/documents/add") {
        navigate(location.state.from, {
          replace: true,
          state: {
            ...location.state,
            from: `/documents/add`,
            document_settings_id: location.state.document_settings_id,

            customer: location.state.customer,
            car: location.state.car,

            works: location.state.works,
            items: location.state.items,

            chosenWork: savedData,
            workRowIndex: location.state.workRowIndex,
          }
        });
      }
    }


    toast.promise(
      savePromise,
      {
        pending: 'מעדכן כרטיס...',
        success: 'הכרטיס עודכן בהצלחה',
        error: {
          render({ data }) {
            return toastErrorHandling(data)
          }
        }
      }
    )
  };



  function returnFunc() {
    if (location.state?.from === "/documents/add") {
      navigate(location.state.from, {
        replace: true,
        state: {
          ...location.state,
          // from: `/documents/add`,
          // document_settings_id: location.state.document_settings_id,

          // works: location.state.works,

          // items: location.state.items,

          // customer: location.state.customer,
          // car: location.state.car,
        }
      });
    }

    else {
      window.history.back();
    }

  }

  const onDelete = async () => {
    console.log("delete, work:", work);

    const deletePromise = async () => {
      if (work) {
        await deleteWork(work.id!);
        window.history.back();
      }
    }

    toast.promise(
      deletePromise,
      {
        pending: 'מוחק כרטיס...',
        success: 'הכרטיס נמחק בהצלחה',
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return toastErrorHandling(data)
          }
        }
      }
    )
  };

  function setStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'form',
        structureName: "עבודה",
        structureType: 'account',
        accountType: "works"
      }
    });
  }

  return (
    <StyledEditWork>
      {!workStructure ? <Skeleton variant="rectangular" height={500} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} /> :
        <>

          <div className="form">
            <div><div className="left">
              {/* <button onClick={setStructure}>הגדרת שדות</button> */}
              <Tooltip title="הגדרת שדות">
                <IconButton onClick={setStructure} sx={{ color: theme.colors.main_color }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div></div>

            <div>
              <Typography variant="body1">פרטים:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["פרטים"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">יצרן ודגם:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["יצרן ודגם"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">הכנסות והוצאות:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["הכנסות והוצאות"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
              <div className="fields">
                {structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

          </div>

          <div className="buttons">
            <div className="right">
              <Button variant="contained" onClick={saveTicket} disabled={form.formState.isSubmitting} >שמירה</Button>
              <Button variant="outlined" onClick={returnFunc}>חזור</Button>
              <Button variant="outlined" color="error" onClick={() => setDialogIsOpen(true)} >מחיקה</Button>
            </div>
            <div className="left">
            </div>
          </div>

        </>
      }
      <AlertDialog
        open={dialogIsOpen}
        handleClose={() => { setDialogIsOpen(false) }}
        handleConfirm={onDelete}
        title="אישור מחיקה"
        message="אתה בטוח שאתה רוצה למחוק כרטיס זה?"
        confirm="מחיקה"
      />
    </StyledEditWork>
  )
}


export default EditWork