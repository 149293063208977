import styled from '@emotion/styled'

export const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    z-index: 999;
    height: 50px;
    background-color: ${({theme})=> theme.colors.header_color};;
    /* box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1); */

    .flex-row{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media only screen and (max-width: 900px) {
        /* CSS styles for mobile devices */
        .menu-icon{
            display: block !important;
        }
    }


`