import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';


z.setErrorMap(customErrorMap);


export const AccountCustomDataSchema = z.object({

    // required
    id: z.number(), // id
    business_id: z.number(), // business object id
    account_id: z.number(), // account object id
    field_index: z.number(), // index

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),

    // nullable
    field_value: z.string().nullable(), // field value
});

export const AccountCustomDataFormSchema = z.object({
    
    // optional in create
    id: z.number().optional(), // id
    business_id: z.number().optional(), // business object id
    account_id: z.number().optional(), // account object id

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    // required
    field_index: z.number(), // index

    // optional nullable fields
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullable()), // field value

});

export const AccountCustomDataSearchSchema = z.object({

    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
});

export type AccountCustomData = z.infer<typeof AccountCustomDataSchema>
export type AccountCustomDataForm = z.infer<typeof AccountCustomDataFormSchema>
export type AccountCustomDataSearch = z.infer<typeof AccountCustomDataSearchSchema>
