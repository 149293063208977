// import MenuIcon from './menu-icon/MenuIcon';
import { StyledHeader } from './header.styled';
import { Grid, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Seperator from '../seperator/Seperator';
import User from './user/User';

interface HeaderProps {
    isMenuOpen: boolean,
    closeMenu: () => void,
    openMenu: () => void,
}

function Header({ isMenuOpen, closeMenu, openMenu }: HeaderProps) {
    return (
        <StyledHeader>
            <Grid container>
                {isMenuOpen ? (
                    <>
                        <Grid item xs={1} md={3} lg={2} >
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={closeMenu}
                                    sx={{float: 'right' }}
                                >
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </Grid>
                            
                        </Grid>
                        <Grid className='flex-row' item xs={8} md={6} lg={7} >
                            <Seperator/>
                        </Grid>
                        <Grid className='flex-row' item xs={3} md={3} lg={3} >
                            <User />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={2}>
                            <Grid item>
                                
                            </Grid>
                        </Grid>
                        <Grid className='flex-row' item xs={7}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={openMenu}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Seperator/>
                        </Grid>
                        <Grid className='flex-row' item xs={3}>
                            <User />
                        </Grid>
                    </>
                )}

            </Grid>
        </StyledHeader>
    )
}

export default Header