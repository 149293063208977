import axiosClient from "../axios-client";
import { DocumentSettings, DocumentSettingsForm } from "../models/document-settings";

export const getDocumentsSettings = async (): Promise<DocumentSettings[]> => {
    const response = await axiosClient.get(`/api/documents-settings`);
    return response.data;
}

export const saveDocumentSettings = async (settings: DocumentSettingsForm, id: number): Promise<DocumentSettings> => {
    const response = await axiosClient.patch(`/api/documents-settings/${id}`, settings);
    return response.data;
}