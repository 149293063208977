import { useTheme } from "@emotion/react";
import { IconButton, Tooltip, Typography } from "@mui/material"
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { DocumentStructure } from "../../../../../models/document-structure";
import MuiInput from "../../../../../components/mui-input/MuiInput";
import { DocumentItemAdd } from "../../../../../models/document-item";

interface itemsProps {

    itemsForm: UseFormReturn<{ items: DocumentItemAdd[] }>;
    itemsFieldArray: UseFieldArrayReturn<{ items: DocumentItemAdd[]}, "items", "id">;

    structure: DocumentStructure[];
    itemRowIndex: number;

    browseItem: () => void;
    addItem: () => void;
    editItem: () => void;

    setItemRowIndex: (rowIndex: number) => void;
    addItemLine: () => void;
    deleteRow: (rowIndex: number, type: string) => void;
    editItemStructure: () => void;

    isFocused: (baseName: string, inputName: string) => boolean;
}


function Items({ itemsForm, itemsFieldArray, structure, itemRowIndex, browseItem, addItem, editItem, setItemRowIndex, addItemLine, deleteRow, editItemStructure, isFocused }: itemsProps) {

    const theme = useTheme();

    return (
        <div className="items">
            <div className="title">
                <div>
                    <Typography variant="body1" component="span">
                        פריטים:
                    </Typography>
                    <Tooltip title="בחירת כרטיס פריט מתוך דפדוף" placement="top">
                        <IconButton onClick={browseItem} sx={{ color: theme.colors.main_color }}>
                            <AutoStoriesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="הוספת כרטיס פריט" placement="top">
                        <span>
                            <IconButton onClick={addItem} disabled={!!itemsForm.getValues().items?.[itemRowIndex]?.id} sx={{ color: theme.colors.main_color }}>
                                <BookmarkAddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="עריכת כרטיס פריט" placement="top">
                        <span>
                            <IconButton onClick={editItem} disabled={!itemsForm.getValues().items?.[itemRowIndex]?.id} sx={{ color: theme.colors.main_color }}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>

                </div>
                <div>
                    <Tooltip title="הגדרת שדות פריט" placement="top">
                        <IconButton onClick={editItemStructure} sx={{ color: theme.colors.main_color }}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <table>
                <tbody>
                    {
                        itemsFieldArray.fields.map((field, rowIndex) => (
                            <tr key={field.id} onClick={() => { setItemRowIndex(rowIndex) }}>
                                <td className="row-number">{rowIndex + 1}.</td>
                                {
                                    structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map((field, fieldIndex) => {
                                        return (field.type === "פריט" && field.show_form) ? <td key={fieldIndex}><MuiInput field={field} variant='outlined' useForm={itemsForm} prefix={`items.${rowIndex}.`} showRequired autoFocus={isFocused(`items.${rowIndex}.`, field.field_name)} /></td> : null
                                    })
                                }
                                <td className="delete-row"><button onClick={() => { deleteRow(rowIndex, 'item') }}>X</button></td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <button onClick={addItemLine}>הוספת שורה</button>
        </div>
    )
}

export default Items