import styled from '@emotion/styled'
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';



export const CssField = styled(FormControl)(({ theme }) => ({

    '& div, & svg, & p': {
        color: theme.colors.input_text_color,
    },
    '& label:not(.Mui-disabled)': {
        color: theme.colors.input_title_text_color,
    },
    // '& div:hover label': {
    //     color: theme.colors.highlight_color,
    // },
    '& div:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `2px solid ${theme.colors.highlight_color}`,
    },
    '& label.Mui-focused': {
        color: theme.colors.highlight_color, // Set your desired color for the focused state
    },
    '& div:hover fieldset': {
        borderColor: `${theme.colors.highlight_color} !important`,
    },




    // '& svg': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .MuiInput-root, & .muirtl-1c1egg1-MuiInputBase-root-MuiOutlinedInput-root, & .muirtl-1p3rk6h-MuiInputBase-root-MuiOutlinedInput-root, & .muirtl-1pnmrwp-MuiTypography-root, & .muirtl-1c6ub2m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, & .muirtl-hfutr2-MuiSvgIcon-root-MuiSelect-icon, & .muirtl-bpeome-MuiSvgIcon-root-MuiSelect-icon, & .muirtl-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.input_border_color,
    // },
    // '& .muirtl-1c1egg1-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .muirtl-1p3rk6h-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .muirtl-1c6ub2m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.highlight_color,
    // },
    // '& .MuiInput-root::before': {
    //     borderBottom: `1px solid ${theme.colors.input_border_color}`
    // },
    // '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    //     borderBottom: `2px solid ${theme.colors.highlight_color}`,
    // },
    // '& .muirtl-qtcxoq-MuiInputBase-root-MuiInput-root-MuiSelect-root': {
    //     color: theme.colors.input_text_color,
    // },
    '& .MuiFormHelperText-root': {
        color: '#d32f2f',
    },

    '& input[type=number]': {
        MozAppearance: 'textfield', // For Firefox
        WebkitAppearance: 'none',   // For Chrome, Safari, and Edge
        appearance: 'textfield',    // General appearance setting
        '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },

    '@media only screen and (max-width: 900px)': {
        width: '100%',
    }
}));

export const CssLTRField = styled(TextField)(({ theme }) => ({

    '& div, & svg, & p': {
        color: theme.colors.input_text_color,
    },
    '& label': {
        color: theme.colors.input_title_text_color,
    },
    // '& div:hover label': {
    //     color: theme.colors.highlight_color,
    // },
    '& div:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `2px solid ${theme.colors.highlight_color}`,
    },
    '& label.Mui-focused': {
        color: theme.colors.highlight_color, // Set your desired color for the focused state
    },
    '& div:hover fieldset': {
        borderColor: `${theme.colors.highlight_color} !important`,
    },

    // '& svg': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .MuiInput-root, & .muirtl-1c1egg1-MuiInputBase-root-MuiOutlinedInput-root, & .muirtl-1p3rk6h-MuiInputBase-root-MuiOutlinedInput-root, & .muirtl-1pnmrwp-MuiTypography-root, & .muirtl-1c6ub2m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root, & .muirtl-hfutr2-MuiSvgIcon-root-MuiSelect-icon, & .muirtl-bpeome-MuiSvgIcon-root-MuiSelect-icon, & .muirtl-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.input_border_color,
    // },
    // '& .muirtl-1c1egg1-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .muirtl-1p3rk6h-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .muirtl-1c6ub2m-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.highlight_color,
    // },
    // '& .MuiInput-root::before': {
    //     borderBottom: `1px solid ${theme.colors.input_border_color}`
    // },
    // '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    //     borderBottom: `2px solid ${theme.colors.highlight_color}`,
    // },
    '& .MuiFormHelperText-root': {
        color: '#d32f2f',
    },
    '& .MuiInputLabel-root, & .MuiFormHelperText-root , & .MuiFormHelperText-root.MuiInputLabel-root': {
        direction: 'ltr'
    },

    '& input[type=number]': {
        MozAppearance: 'textfield', // For Firefox
        WebkitAppearance: 'none',   // For Chrome, Safari, and Edge
        appearance: 'textfield',    // General appearance setting
        '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },

    '@media only screen and (max-width: 900px)': {
        width: '100%',
    }
}));


export const CssDatePicker = styled(DatePicker)(({ theme }) => ({
    '& div, & button:not(Mui-disabled), & p': {
        color: theme.colors.input_text_color,
    },
    '& label': {
        color: theme.colors.input_title_text_color,
    },
    // '& div:hover label': {
    //     color: theme.colors.highlight_color,
    // },
    '& div:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `2px solid ${theme.colors.highlight_color}`,
    },
    '& label.Mui-focused': {
        color: theme.colors.highlight_color, // Set your desired color for the focused state
    },
    '& div:hover fieldset': {
        borderColor: `${theme.colors.highlight_color} !important`,
    },
    // '& .MuiInputBase-root, & .muirtl-e0diwz-MuiInputBase-root-MuiInput-root': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .muirtl-1qyjpfe-MuiFormLabel-root-MuiInputLabel-root': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .muirtl-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:not(.Mui-error, .Mui-focused), & .muirtl-38bjlq-MuiFormLabel-root-MuiInputLabel-root:not(.Mui-error, .Mui-focused), & .muirtl-ul0opo-MuiFormLabel-root-MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .muirtl-e0diwz-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before': {
    //     borderBottom: `2px solid ${theme.colors.highlight_color}`,
    // },
    // '& .muirtl-e0diwz-MuiInputBase-root-MuiInput-root::before': {
    //     borderBottom: `1px solid ${theme.colors.input_border_color}`,
    // },
    // '& .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.input_border_color,
    // },
    // '& .muirtl-yzj0jj-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.highlight_color,
    // },


}));

export const CssTimePicker = styled(TimePicker)(({ theme }) => ({
    '& div, & svg, & p': {
        color: theme.colors.input_text_color,
    },
    '& label': {
        color: theme.colors.input_title_text_color,
    },
    // '& div:hover label': {
    //     color: theme.colors.highlight_color,
    // },
    '& div:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: `2px solid ${theme.colors.highlight_color}`,
    },
    '& label.Mui-focused': {
        color: theme.colors.highlight_color, // Set your desired color for the focused state
    },
    '& div:hover fieldset': {
        borderColor: `${theme.colors.highlight_color} !important`,
    },
    // '& svg': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .MuiInputBase-root, & .muirtl-e0diwz-MuiInputBase-root-MuiInput-root': {
    //     color: theme.colors.input_text_color,
    // },
    // '& .muirtl-1qyjpfe-MuiFormLabel-root-MuiInputLabel-root': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .muirtl-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:not(.Mui-error, .Mui-focused), & .muirtl-38bjlq-MuiFormLabel-root-MuiInputLabel-root:not(.Mui-error, .Mui-focused), & .muirtl-ul0opo-MuiFormLabel-root-MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
    //     color: theme.colors.input_title_text_color,
    // },
    // '& .muirtl-e0diwz-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error)::before': {
    //     borderBottom: `2px solid ${theme.colors.highlight_color}`,
    // },
    // '& .muirtl-e0diwz-MuiInputBase-root-MuiInput-root::before': {
    //     borderBottom: `1px solid ${theme.colors.input_border_color}`,
    // },
    // '& .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.input_border_color,
    // },
    // '& .muirtl-yzj0jj-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    //     borderColor: theme.colors.highlight_color,
    // },


}));
