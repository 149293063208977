import axiosClient from "../axios-client";
import { Car, CarAdd, CarEdit, CarSchema } from "../models/car";

export const getCars = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[] = [], body: object = {}, signal: AbortSignal) => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/cars/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data as Car[];
}


export const getCarById = async (id: string, signal?: AbortSignal): Promise<Car> => {
    const response = await axiosClient.get(`/api/cars/${id}`, {
        signal: signal // Pass the signal to the request options
    });

    return response.data;
}

export const addCar = async (carToAdd: CarAdd) : Promise<Car> => {
    const response = await axiosClient.post(`/api/cars`, carToAdd);
    return response.data;
}


export const saveCar = async (carId: number, carToSave: CarEdit): Promise<Car> => {
    const response = await axiosClient.patch(`/api/cars/${carId}`, carToSave);
    return CarSchema.parse(response.data);
}


export const getNextCarNumber = async () : Promise<number> => {
    const response = await axiosClient.get(`/api/cars/next-number`);
    return response.data.next_car_index;
}


export const deleteCar = async (carId: number): Promise<void> => {
    await axiosClient.delete(`/api/cars/${carId}`);
}