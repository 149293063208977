import { Tab, TabProps, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom';
import { getAccountById } from "../../../services/accountsService";
import { Account, AccountSchema } from "../../../models/account";
import { useLocation } from 'react-router-dom';
import { AccountProvider } from "../../../utils/AccountContext";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../services/errorHandling";


interface LinkTabProps extends TabProps {
  path: string;
  selected?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component={RouterLink}
      to={props.path}
      label={props.label}
      aria-current={props.selected ? 'page' : undefined}
      {...props}
    />
  );
}

// Define an array of tab hrefs
const tabsData = [
  {
    label: "פרטי כרטיס",
    path: "",
  },
  {
    label: "תנועות",
    path: "journal-entries",
  },
  // {
  //   label: "הסטוריה",
  //   path: "history",
  // },
];


function System() {

  const { showBoundary } = useErrorBoundary();
  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = tabsData.findIndex(tab => tab.path !== "" && location.pathname.includes(tab.path));
  const value = currentIndex !== -1 ? currentIndex : 0;

 
  const [system, setSystem] = useState<Account | undefined>();
  const { id } = useParams();


  useEffect(() => {
    if(location.state?.selectedTicket){
      setSystem(location.state.selectedTicket);
      navigate(location.pathname, { replace: true, state: null });
    }

    else{
      getSystem();
    }
  }, []);

  const getSystem = async () => {
    try {
      if (id) {
        const system = await getAccountById(id);
        const systemParse = AccountSchema.parse(system);
        setSystem(systemParse);
      }
    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
  }

  {
    return system && 
    <>
      <div>
        <Typography variant="h5" component="span">
          כרטיס מערכת
        </Typography>

        <span>מס כרטיס: {system?.card_number}</span>
      </div>

      <Tabs
        dir='ltr'
        value={value}
        //textColor="secondary"
        //indicatorColor="secondary"
        role="navigation"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        style={{ direction: "rtl", flexDirection: "row-reverse", backgroundColor: "rgb(35, 48, 68)", padding: 5, marginTop: 20 }}

      >
        {tabsData.map((tab, index) => (
          <LinkTab key={index} label={tab.label} path={tab.path} />
        ))}

      </Tabs>
      <AccountProvider account={system}>
        <Outlet />
      </AccountProvider>
    </>
  }
}

export default System