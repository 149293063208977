import { Breadcrumbs } from "@mui/material";
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { StyledSeperator } from "./Seperator.styled";

function Seperator() {

    const breadcrumbs = [
        <Link
            component={RouterLink}
            to={"/documents"}
            state={{}}
            underline="hover"
            key="1"
            color="inherit"
        >
            מסמכים
        </Link>,
        <Link
            component={RouterLink}
            to={"/documents/add"}
            underline="hover"
            key="2"
            color="inherit"
        >
            הוספת מסמך
        </Link>,
        // <Typography key="3" sx={{ color: 'text.primary' }}>
        //     שליפת מסמך
        // </Typography>,
    ];

    return (
        <StyledSeperator>
            <Breadcrumbs
                separator={<NavigateBeforeIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </StyledSeperator>
    )
}

export default Seperator