import { configureStore } from '@reduxjs/toolkit'
import structure from './features/structure'
import tables from './features/tables'
import browse from './features/browse'
import settings from './features/settings'

export const store = configureStore({
  reducer: {
    structure: structure,
    tables : tables,
    browse : browse,
    settings: settings
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;