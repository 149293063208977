import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { useTheme } from "@emotion/react";
import { useAuth } from '../../../utils/AuthContext';

function User() {

    const theme = useTheme();
    const { user, logoutUser } = useAuth();



    return (
        <div>
            <span>{user?.first_name} {user?.last_name} {user?.email}</span>
            <IconButton onClick={logoutUser}  sx={{ color: theme.colors.main_color }}>
                <LogoutIcon />
            </IconButton>
        </div>
    )
}

export default User