import { Link as RouterLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import Tab, { TabProps } from '@mui/material/Tab';
import { IconButton, Tooltip, Typography } from '@mui/material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { TabsStyled } from "./Tickets.styled";
import { useTheme } from "@emotion/react";


interface LinkTabProps extends TabProps {
  path: string;
  selected?: string
}


function LinkTab(props: LinkTabProps) {

  const theme = useTheme();

  return (
    <Tab
      component={RouterLink}
      to={props.path}
      label={props.label}
      aria-current={props.selected ? 'page' : undefined}
      {...props}
      sx={{
        color: theme.colors.main_color, // Use theme colors dynamically
      }}
    />
  );
}

// Define an array of tab hrefs
const tabsData = [
  {
    label: "לקוחות/ספקים",
    path: "/customers",
  },
  {
    label: "פריטים/מלאי",
    path: "/items",
  },
  {
    label: "קודי עבודה",
    path: "/works",
  },
  {
    label: "רכבים",
    path: "/cars",
  },
  {
    label: "הכנסות",
    path: "/incomes",
  },
  {
    label: "הוצאות",
    path: "/outcomes",
  },
  {
    label: "בנק",
    path: "/banks",
  },
  {
    label: "קופה",
    path: "/cash-boxes",
  },
  {
    label: "מערכת",
    path: "/system",
  },

];


function Tickets() {
  
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const currentIndex = tabsData.findIndex(tab => tab.path === location.pathname);
  const value = currentIndex !== -1 ? currentIndex : 0;



  function addTicket(): void {
    navigate(`${location.pathname}/add`);
  }


  return (
    <>
      <Typography variant="h5" component="span">
        דפדוף בכרטסות
      </Typography>

      {/* <button onClick={addTicket}>+ הוספת כרטיס</button> */}
      <Tooltip title="הוספת כרטיס">
        <IconButton onClick={addTicket} sx={{ color: theme.colors.main_color }}>
          <BookmarkAddIcon />
        </IconButton>
      </Tooltip>

      <TabsStyled
        dir='ltr'
        value={value}
        //textColor="secondary"
        //indicatorColor="secondary"
        role="navigation"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        style={{ direction: "rtl", flexDirection: "row-reverse", padding: 5, marginTop: 20 }}

      >
        {tabsData.map((tab, index) => (
          <LinkTab key={index} label={tab.label} path={tab.path} />
        ))}
      </TabsStyled>
      <Outlet />
    </>
  );
}

export default Tickets;