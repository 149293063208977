import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const RegisterSchema = z.object({

    //business

    business_name: z.preprocess((val) => myPreProcess(val), z.string()),
    business_type: z.preprocess((val) => myPreProcess(val), z.enum(['עוסק פטור','עוסק מורשה','חברה בע"מ'])),
    // business_max_users: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    business_identity_number: z.preprocess((val) => myPreProcess(val), z.string()),
    business_address: z.preprocess((val) => myPreProcess(val), z.string()),
    business_city: z.preprocess((val) => myPreProcess(val), z.string()),
    business_postal_code: z.preprocess((val) => myPreProcess(val), z.string()),


    //user
    user_email: z.preprocess((val) => myPreProcess(val), z.string().email("אימייל לא תקין")),
    user_first_name: z.preprocess((val) => myPreProcess(val), z.string()),
    user_last_name: z.preprocess((val) => myPreProcess(val), z.string()),
    user_password: z.preprocess((val) => myPreProcess(val), z.string().min(8, "הסיסמה צריכה להכיל לפחות 8 תווים")),
    user_password_confirmation: z.preprocess((val) => myPreProcess(val), z.string().min(8, "הסיסמה צריכה להכיל לפחות 8 תווים")),


}).refine((data) => data.user_password === data.user_password_confirmation, {
    path: ["user_password_confirmation"], // Highlight the confirmPassword field
    message: "אימות סיסמה אינו תואם",
});;

export type RegisterModel = z.infer<typeof RegisterSchema>