import styled from '@emotion/styled'

export const StyledTable = styled.div`

  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  background: ${({theme})=> theme.colors.section_color};
  min-height: 500px;
  padding: 10px;


  .table-container{
    max-height: 450px;
    overflow-y: auto;
  }

  table{
    border-spacing: 2px 8px;
  }

  .expand{
    width: 100%;
  }

  .clickable{
    cursor: pointer;
  }

  .buttons{
    display: flex;
    gap: 10px;
  }
`