import { IconButton, Tooltip, Typography } from "@mui/material"
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CustomerAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from "react-router-dom";
import { Document, DocumentAdd } from "../../../../../models/document";
import { UseFormReturn } from "react-hook-form";
import SettingsIcon from '@mui/icons-material/Settings';
import { DocumentStructure } from "../../../../../models/document-structure";
import MuiInput from "../../../../../components/mui-input/MuiInput";
import { DocumentSettings } from "../../../../../models/document-settings";
import { Account } from "../../../../../models/account";


// theme
import { useTheme } from "@emotion/react";



//state properties:
interface LocationState {

    reference?: string | null;
    customer?: Account | null;
    document?: Document | null;
}


interface CustomerProps {

    documentForm: UseFormReturn<DocumentAdd>
    settings: DocumentSettings;
    structure: DocumentStructure[];

    browseCustomer: () => void;
    addCustomer: () => void;
    editCustomer: () => void;

    editAccountStructure: () => void;
}




function Customer({ documentForm, settings, structure, browseCustomer, addCustomer, editCustomer, editAccountStructure}: CustomerProps) {


    const { state } = useLocation() as { state: LocationState; }; // state data from another page
    const theme = useTheme();



    function renderAccountField(field: DocumentStructure) {

        const isAccountNumberField = field.field_name === "customer.account_number";

        const shouldDisableCustomerAccountField = state?.reference || (!settings?.display_items && !settings?.display_works && settings?.display_payments && documentForm.getValues("customer.account_number"));
        const isCustomerField = field.type === "לקוח-ספק" && field.show_form;

        if (isCustomerField && isAccountNumberField && shouldDisableCustomerAccountField) {
            return <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired disabled />;
        }


        if (isCustomerField) {
            return <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired />;
        }

        // Return null if none of the conditions are met
        return null;
    }



    return (
        <div className="customer">
            <div className="title">
                <div>
                    <Typography variant="body1" component="span">
                        פרטי לקוח:
                    </Typography>

                    <Tooltip title="בחירת כרטיס לקוח-ספק מתוך דפדוף" placement="top">
                        <IconButton onClick={browseCustomer} sx={{ color: theme.colors.main_color }}>
                            <AutoStoriesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="הוספת כרטיס לקוח-ספק" placement="top">
                        <span>
                            <IconButton onClick={addCustomer} disabled={!!state?.customer?.id || !!state?.document?.customer?.customer_id} sx={{ color: theme.colors.main_color }}>
                                <CustomerAddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="עריכת כרטיס לקוח-ספק" placement="top">
                        <span>
                            <IconButton onClick={editCustomer} disabled={!state?.customer?.id && !state?.document?.customer?.customer_id} sx={{ color: theme.colors.main_color }}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title="הגדרת שדות לקוח-ספק" placement="top">
                        <IconButton onClick={editAccountStructure} sx={{ color: theme.colors.main_color }}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <div className="inputs">
                {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => renderAccountField(field))}

            </div>
        </div>
    )
}

export default Customer