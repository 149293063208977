import MuiInput from '../mui-input/MuiInput';
import { StyledSearchTable } from './searchTable.styled';
import { AccountStructure } from '../../models/accounts-structure';
import { Button, IconButton, Tooltip } from '@mui/material';
import { DocumentStructure } from '../../models/document-structure';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from "@emotion/react";


interface SearchTableProps<T extends FieldValues> {
    structure: AccountStructure[] | DocumentStructure[];
    style: object;
    useForm: UseFormReturn<T>;
    setStructure: () => void;
    reset: () => void;
}




function SearchTable<T extends FieldValues>({ structure, useForm, setStructure, reset }: SearchTableProps<T>) {

    // function reset(){
    //     useForm?.reset();

    // }

    const theme = useTheme();


    return (
        <StyledSearchTable>
            {
                structure.sort((a, b) => (a.location || 0) - (b.location || 0)).map((field) => (
                    <MuiInput key={field.field_name} field={field} useForm={useForm} variant='standard' />
                ))
            }
            <Button onClick={reset} variant='outlined'>איפוס</Button>
            {/* <button onClick={setStructure}>הגדרת שדות</button> */}
            <Tooltip title="הגדרת שדות">
                <IconButton onClick={setStructure} sx={{ color: theme.colors.main_color }}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
        </StyledSearchTable>
    )
}

export default SearchTable