import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { deleteEmptyFieldsFromObject, groupBy } from "../../../../helpers/helpers";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setCarsStructure } from "../../../../redux/features/structure";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { handleApiErrors, toastErrorHandling } from "../../../../services/errorHandling";
import AlertDialog from "../../../../components/alert-dialog/AlertDialog";
import { CarContext } from "../../../../utils/CarContext";
import { deleteCar, saveCar } from "../../../../services/carsService";
import { StyledEditCar } from "./EditCar.styled";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from "@emotion/react";
import { setCustomDataArray } from "../../../../services/customDataService";
import { CarEdit, CarEditSchema } from "../../../../models/car";
import { useErrorBoundary } from "react-error-boundary";

function EditCar() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const theme = useTheme();
  const { showBoundary } = useErrorBoundary();

  const carStructure = useSelector((state: RootState) => state.structure.cars);
  const structureSortedByTitle = groupBy(carStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure => !!structure.show_form === true);

  const { car, setCar } = useContext(CarContext);


  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const form = useForm<CarEdit>({
    resolver: zodResolver(CarEditSchema),
    defaultValues: {
      ...deleteEmptyFieldsFromObject(car)
    }
  });


  useEffect(() => {
    if (!carStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);

  useEffect(() => {
    if (car) {
      form.reset(car);
    }
  }, [car]);


  async function getStructureAndSaveAtStore() {
    try {

      const structure = await getAccountsStructure({
        account_type: "רכב"
      });

      dispatch(setCarsStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
  }

  const saveTicket = async () => {

    try {

      
      // const customDataObj = filterCustomKeys(form.getValues());
      // const customDataArray = returnArrayOfCustomData(customDataObj as FormData).filter(obj => obj.field_value !== "");
      const customDataArray = form.getValues("custom_data");
      setCustomDataArray(customDataArray);
      form.setValue("custom_data", customDataArray);

      form.handleSubmit(onSubmit)();

    } catch (error) {
      console.error("Form submission failed:", error);
    }

  }

  // function filterCustomKeys<T extends object>(inputObject: T): Partial<T> {
  //   const result: Partial<T> = {};

  //   for (const key in inputObject) {
  //     if (Object.prototype.hasOwnProperty.call(inputObject, key) && key.includes("custom") && key !== "custom_data") {
  //       result[key] = inputObject[key];
  //     }
  //   }

  //   return result;
  // }

  // function returnArrayOfCustomData(formData: FormData): customData[] {

  //   const array: customData[] = Object.keys(formData).map(key => ({
  //     field_name: key,
  //     field_value: formData[key]
  //   }));

  //   return array;
  // }


  const onSubmit: SubmitHandler<CarEdit> = (data) => {

    // setCar(data);

    // const dataToSend = deleteEmptyFieldsFromObject(data);

    const savePromise = async () => {
      const savedData = await saveCar(car!.id, data);
      setCar(savedData);

      if (location.state?.from === "/documents/add") {
        navigate(location.state.from, {
          replace: true,
          state: {
            ...location.state,
            from: `/documents/add`,
            document_settings_id: location.state.document_settings_id,

            customer: location.state.customer,
            car: savedData,

            works: location.state.works,
            items: location.state.items,

          }
        });
      }
    }


    toast.promise(
      savePromise,
      {
        pending: 'מעדכן כרטיס...',
        success: 'הכרטיס עודכן בהצלחה',
        error: {
          render({ data }) {
            return toastErrorHandling(data)
          }
        }
      }
    )
  };

  function returnFunc() {
    if (location.state?.from === "/documents/add") {
      navigate(location.state.from, {
        replace: true,
        state: {
          ...location.state,
          // from: `/documents/add`,
          // document_settings_id: location.state.document_settings_id,

          // works: location.state.works,

          // items: location.state.items,

          // customer: location.state.customer,
          // car: location.state.car,
        }
      });
    }

    else {
      window.history.back();
    }

  }

  const onDelete = async () => {

    const deletePromise = async () => {
      if (car) {
        await deleteCar(car.id!);
        window.history.back();
      }
    }

    toast.promise(
      deletePromise,
      {
        pending: 'מוחק כרטיס...',
        success: 'הכרטיס נמחק בהצלחה',
        error: {
          render({ data }) {
            return toastErrorHandling(data)
          }
        }
      }
    )
  };

  function setStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'form',
        structureName: "רכב",
        structureType: 'account',
        accountType: "cars"
      }
    });
  }

  return (
    <StyledEditCar>
      {!carStructure ? <Skeleton variant="rectangular" height={500} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} /> :
        <>

          <div className="form">
            <div><div className="left">
              {/* <button onClick={setStructure}>הגדרת שדות</button> */}
              <Tooltip title="הגדרת שדות">
                <IconButton onClick={setStructure} sx={{ color: theme.colors.main_color }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div></div>

            <div>
              <Typography variant="body1">פרטים:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["פרטים"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">יצרן ודגם:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["יצרן ודגם"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">הכנסות והוצאות:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["הכנסות והוצאות"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
              <div className="fields">
                {structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

          </div>

          <div className="buttons">
            <div className="right">
              <Button variant="contained" onClick={saveTicket} disabled={form.formState.isSubmitting} >שמירה</Button>
              <Button variant="outlined" onClick={returnFunc}>חזור</Button>
              <Button variant="outlined" color="error" onClick={() => setDialogIsOpen(true)} >מחיקה</Button>
            </div>
            <div className="left">
            </div>
          </div>

        </>
      }
      <AlertDialog
        open={dialogIsOpen}
        handleClose={() => { setDialogIsOpen(false) }}
        handleConfirm={onDelete}
        title="אישור מחיקה"
        message="אתה בטוח שאתה רוצה למחוק כרטיס זה?"
        confirm="מחיקה"
      />
    </StyledEditCar>
  )
}


export default EditCar