export const deleteEmptyFieldsFromObject = <T>(obj: T): T => {
    // Create a shallow copy of the object
    const objCopy = { ...obj };

    for (const key in objCopy) {
        if (Object.prototype.hasOwnProperty.call(objCopy, key)) {
            const value = objCopy[key];

            if (
                value == null ||
                value === -1 ||
                (typeof value === 'string' && value.trim() === '')
            ) {
                delete objCopy[key];
            } else if (Array.isArray(value)) {
                const cleanedArray = value
                    .map((item) => (typeof item === 'object' && item !== null ? deleteEmptyFieldsFromObject(item) : item))
                    .filter((item) => {
                        if (typeof item === 'object' && item !== null) {
                            return Object.keys(item).length > 0;
                        }
                        return item != null && item !== -1 && (typeof item !== 'string' || item.trim() !== '');
                    });

                if (cleanedArray.length > 0) {
                    (objCopy as any)[key] = cleanedArray;
                } else {
                    delete objCopy[key];
                }
            } else if (typeof value === 'object' && value !== null) {
                const cleanedObject = deleteEmptyFieldsFromObject(value);

                if (Object.keys(cleanedObject).length > 0) {
                    (objCopy as any)[key] = cleanedObject;
                } else {
                    delete objCopy[key];
                }
            }
        }
    }

    return objCopy;
};

// export const convertEmptyFieldsToNull = <T extends object>(obj: T): { [K in keyof T]: T[K] | null } => {
//     const objCopy: { [K in keyof T]: T[K] | null } = { ...obj };

//     for (const key in obj) {
//         if (Object.prototype.hasOwnProperty.call(obj, key)) {
//             objCopy[key] = obj[key] === '' ? null : obj[key];
//         }
//     }

//     return objCopy;
// };

export const groupBy = <T>(array: T[] | undefined, property: keyof T) => {
    return array?.reduce((acc, obj) => {
        // Get the value of the property
        const key = String(obj[property]);
        // If the key doesn't exist in the accumulator, create an empty array for it
        if (!acc[key]) {
            acc[key] = [];
        }
        // Push the object into the corresponding group array
        acc[key].push(obj);
        return acc;
    }, {} as Record<string, T[]>);
}


// date object to string, format DD/MM/YYYY
export const dateToString = (date: Date) => {

    // Extract day, month, and year from the date object
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const year = date.getFullYear();

    // Return the formatted date string
    return `${day}/${month}/${year}`;
}


export const compareObjects = <T>(obj1: T, obj2: T): boolean => {
    if (obj1 === obj2) {
        return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1) as Array<keyof T>;
    const keys2 = Object.keys(obj2) as Array<keyof T>;

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (!keys2.includes(key)) {
            return false;
        }

        const val1 = obj1[key];
        const val2 = obj2[key];

        const areObjects = typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null;
        if (
            (areObjects && !compareObjects(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

export const fixedNumber = (number: number, digits: number = 2): number => {

    return Number(number.toFixed(digits));
}


export const pick = <T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> => {
    return keys.reduce((result, key) => {
        if (key in obj) {
            result[key] = obj[key];
        }
        return result;
    }, {} as Pick<T, K>);
}

export const omit = <T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
    const result: Partial<T> = {};

    Object.keys(obj).forEach(key => {
        if (!keys.includes(key as K)) {
            result[key as K] = obj[key as K];
        }
    });

    return result as Omit<T, K>;
}


export const updateArrayById = <T extends { id: number }>(originalArray: T[] | undefined, updatedArray: Partial<T>[]): T[] => {

    if (!originalArray) {
        return [];
    }

    if (!updatedArray) {
        return originalArray;
    }

    const updatedMap = new Map<number, Partial<T>>();

    // Add updated items to the map for quick lookup
    updatedArray.forEach(obj => {
        if (obj.id !== undefined) {
            updatedMap.set(obj.id, obj);
        }
    });

    // Create a new array with updated items
    return originalArray.map(obj => {
        const updatedItem = updatedMap.get(obj.id);
        return updatedItem ? { ...obj, ...updatedItem } : obj;
    });
};



export const setAllValuesToNull = <T>(obj: T): T => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(item => setAllValuesToNull(item)) as unknown as T;
    }

    const newObj: any = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = (obj as any)[key];
            if (typeof value === 'object' && value !== null) {
                newObj[key] = setAllValuesToNull(value);
            } else {
                newObj[key] = null;
            }
        }
    }

    return newObj;
}


export const padWithZeros = (num: number | undefined, width: number): string => {

    if(!num){
        return '';
    }

    const numStr = num.toString();
    return numStr.padStart(width, '0');
}


export const checkInputValue = (value: string | number | boolean | null | undefined, expected: string = 'string'): string | number | null =>{

    

    if (value === null || value === undefined) {

        // if (expected === 'number') {
        //     return null;
        // }

        return "";
    }

    if(expected === 'index' && value === 0){
        return "";
    }

    if (typeof value === 'boolean') {
        return value ? 1 : 0;
    }

    if (typeof value === 'string' && expected === 'number') {
        const num = parseFloat(value);
        return isNaN(num) ? value : num;
    }


    return value;

}

export const getValueByPath = <T>(obj: Record<string, any>, path: string): T | undefined =>{
    // Split the path into keys
    const keys = path.split('.');
  
    // Traverse the object using the keys
    return keys.reduce((acc: any, key: string) => {
      // Check if the current level exists and continue traversal
      return acc && acc[key] !== undefined ? acc[key] : undefined;
    }, obj) as T;
  }