import { UseFormReturn } from "react-hook-form";
import { DocumentSettings } from "../../../../../models/document-settings"
import { DocumentAdd } from "../../../../../models/document";
import { Typography } from "@mui/material";

interface SummaryProps {

    documentForm: UseFormReturn<DocumentAdd>;

    settings: DocumentSettings;
}

function Summary({ documentForm, settings }: SummaryProps) {
    return (
        <div className="summary">

            {(settings.display_items || settings.display_works) && (
                <div className="summary-block">
                    <div className="summary-block-title">
                        <Typography variant="body1" component="span">
                            סיכום חשבונית:
                        </Typography>
                    </div>
                    <div className="summary-block-details">
                        <Typography variant="body1" component="div">
                            סה"כ כללי: {documentForm.getValues("total")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            הנחה: <span dir="ltr">{documentForm.getValues("discount")}₪</span> <span dir="ltr">{"(" + documentForm.getValues("discount_percentage") + "%)"}</span>
                        </Typography>
                        <Typography variant="body1" component="div">
                            סכום לפני מע"מ: {((documentForm.getValues("include_vat") || 0) + (documentForm.getValues("not_include_vat") || 0)).toFixed(2)}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            פטור ממס: {documentForm.getValues("not_include_vat")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            חייב במס: {documentForm.getValues("include_vat")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            17% מע"מ: {documentForm.getValues("vat")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            לתשלום: {documentForm.getValues("to_pay")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            תאריך פירעון:
                        </Typography>
                    </div>
                </div>
            )}

            {settings.type === "קבלה" && (
                <div className="summary-block">
                    <div className="summary-block-title">
                        <Typography variant="body1" component="span">
                            סיכום חשבוניות:
                        </Typography>
                    </div>
                    <div className="summary-block-details">
                        <Typography variant="body1" component="div">
                            סה"כ: {documentForm.getValues("to_pay")}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            ניכוי במקור:
                        </Typography>
                        <Typography variant="body1" component="div">
                            הנחה:
                        </Typography>
                        <Typography variant="body1" component="div">
                            לתשלום: {documentForm.getValues("to_pay")}₪
                        </Typography>
                    </div>
                </div>
            )}

            {settings.type === "הפקדה" && (
                <div className="summary-block">
                    <div className="summary-block-details">
                        <Typography variant="body1" component="div">
                            סה"כ: {documentForm.getValues("deposit") || 0}₪
                        </Typography>
                    </div>
                </div>
            )}

            {settings.type === "הוצאה מהקופה" && (
                <div className="summary-block">
                    <div className="summary-block-details">
                        <Typography variant="body1" component="div">
                            סה"כ: {documentForm.getValues("to_pay")}₪
                        </Typography>
                    </div>
                </div>
            )}

            {(settings.type === "חשבונית מס קבלה" || settings.type === "קבלה") && (
                <div className="summary-block">
                    <div className="summary-block-title">
                        <Typography variant="body1" component="span">
                            משולם:
                        </Typography>
                    </div>
                    <div className="summary-block-details">
                        <Typography variant="body1" component="div">
                            סה"כ: {(documentForm.getValues("receipt") || 0)}₪
                        </Typography>
                        <Typography variant="body1" component="div">
                            יתרה: {documentForm.getValues("balance") || 0}₪
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Summary