import styled from '@emotion/styled'
import { NavLink } from "react-router-dom";

export const StyledMainMenu = styled.div`

    height: 100%;
    
    ul{
        background-color: ${({ theme }) => theme.colors.section_color};
    }
    
    .short-menu{
        display: none;
    }

    @media only screen and (max-width: 900px) {
        /* CSS styles for mobile devices */
        .long-menu{
            display: none; 
        }

        .short-menu{
            display: block;
        }
    }


`

export const CssNavLink = styled(NavLink)(({ theme }) => ({
    color: theme.colors.main_color,
    textDecoration: 'none',
    '&:hover': {
        color: theme.colors.highlight_color, // Color on hover
    },
    '&.active': {
        color: theme.colors.highlight_color, // Color for the active link
    },
}));