import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentSettingsSchema } from "./document-settings";


z.setErrorMap(customErrorMap);


export const EntryDocumentSchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    account_id: z.number(),
    document_settings_id: z.number(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    document_number: z.number(),
    date_time: z.string(),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    


    // nullable
    works_total: z.number().nullable(),
    items_total: z.number().nullable(),
    discount: z.number().nullable(),
    total: z.number().nullable(),
    vat: z.number().nullable(),
    to_pay: z.number().nullable(),
    balance: z.number().nullable(),
    reference: z.string().nullable(),

    
    // loads
    document_settings: DocumentSettingsSchema.optional(),

});
