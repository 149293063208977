import { Typography } from "@mui/material"
import { StyledHistory } from "./History.styled"
import { AccountContext } from "../../../utils/AccountContext";
import { useContext, useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridLocaleText } from '@mui/x-data-grid';
import { getDocuments } from "../../../services/documentsService";
import { Document } from "../../../models/document";
import { dateToString, padWithZeros } from "../../../helpers/helpers";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../services/errorHandling";

function History() {

    const { account } = useContext(AccountContext);
    const [documents, setDocuments] = useState<Document[]>();
    // const rows = account?.entries || [];
    const abortControllerRef = useRef<AbortController | null>(null);
    const { showBoundary } = useErrorBoundary();

    useEffect(()=>{
        if(account?.id){
            getAccountDocuments();
        }
        
    },[]);

    const getAccountDocuments = async () => {
        try {
            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            const documents = await getDocuments("id", "0", "ASC", ["documentSettings", "customer", "car"], { account_id: account?.id }, abortControllerRef.current.signal);
            setDocuments(documents);
            
            
        } catch (error) {
            handleApiErrors(error, showBoundary);
        }
    }

    // const demo: Document[] = [
    //     {
    //         "id": 5,
    //         "business_id": 5,
    //         "document_settings_id": 5,
    //         "account_id": 11,
    //         "document_number": 1,
    //         "date": new Date("2024-05-28"),
    //         "time": new Date("2024-05-28"),
    //         "account_number": 1002,
    //         "account_name": "לקוח 2",
    //         "identity_number": "123456789",
    //         "license_number": "2286334",
    //         "manufacturer": 1,
    //         "model": 1,
    //         "status": "סגור",
    //         "info": "",
    //         "works_total": null,
    //         "items_total": 200,
    //         "discount": null,
    //         "total": 200,
    //         "vat": 17,
    //         "to_pay": 234,
    //         "balance": 234,
    //         "reference": "",
    //         "created_at": new Date("2024-05-28T07:02:34.000000Z"),
    //         "updated_at": new Date("2024-05-28T07:02:34.000000Z"),
    //         "document_settings": {
    //             "id": 5,
    //             "business_id": 5,
    //             "name": "חשבונית מס קבלה",
    //             "short_name": "חק",
    //             "display_works": false,
    //             "circle": true,
    //             "accounting": true,
    //             "editable": false,
    //             "created_at": new Date("2024-05-28T07:02:34.000000Z"),
    //             "updated_at": new Date("2024-05-28T07:02:34.000000Z"),
    //             // "structure": []
    //         },
    //         // "works": [],
    //         // "items": [],
    //         // "custom_data": [],
    //         // "payments": []
    //     }
    // ];

    const customLocaleText: Partial<GridLocaleText> = {

        // Table Content
        noRowsLabel: 'אין רשומות.',

        // Labels
        columnMenuLabel: 'תפריט',
        columnHeaderSortIconLabel: 'מיון',

        // Columns text
        columnMenuUnsort: "ביטול מיון",
        columnMenuSortAsc: "מיון א-ת 9-1",
        columnMenuSortDesc: "מיון ת-א 1-9",
        columnMenuFilter: "סינון",
        columnMenuHideColumn: "הסתר עמודה",
        columnMenuShowColumns: "הצג עמודות",
        columnMenuManageColumns: "ניהול עמודות",

        // Columns Management
        columnsManagementShowHideAllText: "הצג/הסתר הכל",
        columnsManagementReset: "איפוס",
        columnsManagementSearchTitle: "חיפוש",

        // Filter Panel text
        filterPanelOperator: 'פעולה',
        filterPanelOperatorAnd: 'ו-',
        filterPanelOperatorOr: 'או',
        filterPanelColumns: 'עמודה',
        filterPanelInputLabel: 'ערך',
        filterPanelInputPlaceholder: 'ערך לסינון',

        // Filter operators text
        filterOperatorContains: 'מכיל',
        filterOperatorEquals: 'שווה',
        filterOperatorStartsWith: 'מתחיל עם',
        filterOperatorEndsWith: 'נגמר עם',
        filterOperatorIsEmpty: 'הוא ריק',
        filterOperatorIsNotEmpty: 'הוא לא ריק',
        filterOperatorIsAnyOf: 'כל אחד מ',

        filterOperatorIs: 'הוא',
        filterOperatorNot: 'הוא לא',
        filterOperatorAfter: 'הוא אחרי',
        filterOperatorOnOrAfter: 'הוא מ',
        filterOperatorBefore: 'הוא לפני',
        filterOperatorOnOrBefore: 'הוא עד',

        noResultsOverlayLabel: 'לא נמצאו תוצאות.',

        // Pagination
        MuiTablePagination: {
            labelRowsPerPage: 'מס שורות מקסימלי לעמוד:',
            labelDisplayedRows: ({ from, to, count }) =>
                `${from}–${to} מתוך ${count !== -1 ? count : `יותר מ- ${to}`}`,
            backIconButtonProps: {
                sx: { transform: "rotate(180deg)" }
            },
            nextIconButtonProps: {
                sx: { transform: "rotate(180deg)" }
            }
        },
    };

    // const document = (value: string, row: ) => {
    //     return `${row.firstName || ''} ${row.lastName || ''}`;
    // }



    const columns: GridColDef[] = [
        { field: 'document', headerName: 'מסמך', width: 130, valueGetter: (_, row) => `${row.document_settings.short_name} ${ padWithZeros(row.document_number, 7)} ${row.status}`},
        { field: 'reference', headerName: 'אסמכתא', width: 130 },
        { field: 'account_number', headerName: 'מספר חשבון', width: 130, valueGetter: (_, row) => `${row.customer.account_number}`},
        { field: 'account_name', headerName: 'שם', width: 130 , valueGetter: (_, row) => `${row.customer.account_name}`},
        { field: 'date_time', headerName: 'תאריך', type: 'string', valueGetter: (value: string) => dateToString(new Date(value.split(" ")[0])), width: 130 },
        { field: 'license_number', headerName: 'מס.רישוי', valueGetter: (_, row) => `${row.car?.license_number || ""}`},
        { field: '3', headerName: 'שם פריט', width: 130 },
        { field: '4', headerName: 'כמות', width: 130 },
        { field: 'total', headerName: 'סכום', width: 130 , valueGetter: (_, row) => `${row.total?.toFixed(2) || ""}`},
    ];

    return (
        <StyledHistory>
            <div className="container">
                <div className="top">
                    <div>
                        <Typography variant="body1" component={"span"}>מספר חשבון:</Typography>
                        <Typography variant="body2" component={"span"}>{account?.account_number}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1" component={"span"}>שם חשבון:</Typography>
                        <Typography variant="body2" component={"span"}>{account?.account_name}</Typography>
                    </div>
                </div>
                {documents && (
                    <DataGrid
                    disableRowSelectionOnClick
                    columns={columns}
                    rows={documents}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    // rowSelection={false}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    localeText={customLocaleText}
                    sx={{
                        '& .MuiDataGrid-topContainer svg': {
                            color: "rgba(0,0,0,0.57)",
                        },
                        '& .MuiDataGrid-virtualScrollerContent, .MuiTablePagination-root, .MuiSvgIcon-root': {
                            color: 'rgba(255,255,255,0.7)',

                        }
                    }}
                />
                )}
                
            </div>
        </StyledHistory>
    )

}

export default History