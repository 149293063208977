import axiosClient from "../axios-client";
import { RegisterModel } from "../models/register";
import { User } from "../models/user";


export const login = async (email: string, password: string) : Promise<User> => {
    const response = await axiosClient.post(`/login`, 
    {
        email: email,
        password: password
    });

    const token = response?.data?.token;
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return response.data.user;
}

export const getUser = async () : Promise<User> => {
    const response = await axiosClient.get('/api/user');
    return response.data;
}

export const register = async (registerInfo: RegisterModel): Promise<User> => {
    const response = await axiosClient.post(`/register`,  registerInfo);

    const token = response?.data?.token;
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return response.data.user;
}

export const logout = async (): Promise<void> => {
    await axiosClient.post(`/logout`);

}