import { useNavigate } from "react-router-dom";
import { StyledNotFoundPage } from "./not-found.style";

function NotFound() {

    const navigate = useNavigate();


    return (
        <StyledNotFoundPage>
            <h1>עמוד זה אינו קיים</h1>
            <button onClick={()=>{ navigate("/")}}>חזור לעמוד הראשי</button>
        </StyledNotFoundPage>
    );
}

export default NotFound;