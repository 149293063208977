import { z } from "zod";

export const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    
    // console.log("issue", issue);
    // console.log("ctx", ctx);

    if (issue.code === z.ZodIssueCode.invalid_type) {
        if (issue.received === "undefined") {

            return { message: "שדה חובה." };
        }

        if (issue.received === "null") {
            return { message: "שדה חובה." };
        }

        if (issue.received === "nan") {

            return { message: "שדה זה חייב להכיל מספר." };
        }

        if (issue.expected === "number" && issue.received === "string") {
            return { message: "שדה זה חייב להכיל מספר." };
        }
    }

    if (issue.code === z.ZodIssueCode.too_small) {
        if (issue.type === "string") {
            return { message: "שדה זה הוא חובה." };
        }
        if (issue.type === "number") {
            return { message: `המספר חייב להיות גדול מ-${issue.minimum as number}.` };
        }
    }

    return { message: ctx.defaultError };

}


export const myPreProcess = <T, K>(val: T, convertedVal?: K, fieldName?: string): K | T | undefined | null => {

    if(fieldName){
        console.log(`myPreProcess fieldName: ${fieldName}, val: ${val}, convertedVal: ${convertedVal}`);
    }

    if (val === undefined) { // not registered, return undefined
        return undefined;
    }

    else if (val === "" || val === null) { // registered but empty, return null
        return  null;
    }

    else if (Array.isArray(val) && val.length === 0) { // if empty array, return undefined
        return undefined;
    }

    else if (typeof convertedVal === "boolean") { // if boolean return boolean (to avoid from returning 0 instead of false)
        return convertedVal;
    }

    else if (typeof convertedVal === "number") { // if boolean return boolean (to avoid from returning 0 instead of false)
        return convertedVal;
    }

    else{
        return convertedVal || val;
    }
}