import { z } from "zod";
import { customErrorMap } from "../services/zodService";
import { CarAddSchema, CarEditSchema, CarSchema } from "./car";
import { DocumentCarCustomDataFormSchema, DocumentCarCustomDataSchema } from "./document-car-custom-data";

z.setErrorMap(customErrorMap);

export const DocumentCarSchema = CarSchema.extend({
    document_id: z.number(), // document object id.
    car_id: z.number(), // car object id.

    // loads
    custom_data: z.array(DocumentCarCustomDataSchema).nullish(), // car custom data

});

export const DocumentCarAddSchema = CarAddSchema.extend({

    id: z.number().optional(),

    document_id: z.number().optional(),  // document object id.
    car_id: z.number(), // car object id.

    // loads
    custom_data: z.array(DocumentCarCustomDataFormSchema).nullish(), // car custom data

});

export const DocumentCarEditSchema = CarEditSchema.extend({

    id: z.number().optional(),

    document_id: z.number().optional(),  // document object id.
    car_id: z.number(), // car object id.

    // loads
    custom_data: z.array(DocumentCarCustomDataFormSchema).nullish(), // car custom data

});

export type DocumentCar = z.infer<typeof DocumentCarSchema>
export type DocumentCarAdd = z.infer<typeof DocumentCarAddSchema>
export type DocumentCarEdit = z.infer<typeof DocumentCarEditSchema>