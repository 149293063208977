import axiosClient from "../axios-client";
import { ManufacturerModel } from "../models/manufacturer-model";
import { Table } from "../models/table";
import { InitialState } from "../redux/features/tables";

export const getSystemTables = async (): Promise<Table[]> => {
    const response = await axiosClient.get(`/api/system-tables`);
    return response.data;
}

export const getSystemTableByNumber = async (tableNumber: number): Promise<Table[]> => {
    const response = await axiosClient.get(`/api/system-tables-items/${tableNumber}`);
    return response.data;
}

export const getAllModels = async (): Promise<ManufacturerModel[]> => {
    const response = await axiosClient.get(`api/models`);
    return response.data;
}

export const getModelsTableByManufacturerID = async (manufacturerId: number, orderBy: string = "name", sortMethod: string = "asc"): Promise<ManufacturerModel[]> => {
    const response = await axiosClient.post(`api/models/search/0/${orderBy}/${sortMethod}`, {
        "manufacturer_id": manufacturerId
    });
    return response.data;
}

export const editSystemTableName = async (systemTableToUpdate: Table): Promise<Table> => {
    const response = await axiosClient.patch(`api/system-tables/${systemTableToUpdate.id}`, systemTableToUpdate);
    return response.data;
}

export const editSystemTableItemByItemId = async (systemTableNumber: number, item: Table): Promise<Table> => {
    console.log("editSystemTableItemByItemId item", item);
    const response = await axiosClient.patch(`api/system-tables-items/${systemTableNumber}/${item.id}`, item);
    return response.data;
}

export const addSystemTableItem = async (systemTableNumber: number, itemIndex: number): Promise<Table> => {
    const response = await axiosClient.post(`api/system-tables-items/${systemTableNumber}`, {
        index: itemIndex
    });
    return response.data;
}

export const editModel = async (model: Table): Promise<Table> => {
    const response = await axiosClient.patch(`api/models/${model.id}`, model);
    return response.data;
}

export const addModel = async (manufacturerId: number, modelIndex: number): Promise<Table> => {
    const response = await axiosClient.post(`api/models`, {
        manufacturer_id: manufacturerId,
        index: modelIndex
    });
    return response.data;
}


export const getSystemTableNameFromStore = (tableNumber: number | null): keyof InitialState | undefined => {
    switch (tableNumber) {
        case 1:
            return "manufacturersTable";
        case 2:
            return "warehousesTable";
        case 3:
            return "tagsTable";
        case 4:
            return "sortCodesTable";
        case 5:
            return "itemGroupsTable";
        case 6:
            return "banksTable";

        default:
            break;
    }
}


// custom tables

export const getCustomTables = async (): Promise<Table[]> => {
    const response = await axiosClient.post(`/api/custom-tables/search/0/index/asc`);
    return response.data;
}

export const editCustomTable = async (table: Table): Promise<Table> => {
    const response = await axiosClient.patch(`/api/custom-tables/${table.id}`, table);
    return response.data;
}

export const addCustomTable = async (index: number): Promise<Table> => {
    const response = await axiosClient.post(`/api/custom-tables`, {
        index: index,
        name: ''
    });
    return response.data;
}


export const getCustomTableItems = async (tableId: number): Promise<Table[]> => {
    const response = await axiosClient.post(`/api/custom-tables-items/search/0/index/asc`, {
        custom_table_id: tableId
    });
    return response.data;
}

export const editCustomTableItem = async (tableItem: Table): Promise<Table> => {
    const response = await axiosClient.patch(`/api/custom-tables-items/${tableItem.id}`, tableItem);
    return response.data;
}


export const addCustomTableItem = async (customTableId: number, index: number): Promise<Table> => {
    const response = await axiosClient.post(`/api/custom-tables-items`, {
        custom_table_id: customTableId,
        index: index,
        name: ''
    });
    return response.data;
}
