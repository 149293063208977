import { z } from "zod";
import { AccountCustomDataFormSchema, AccountCustomDataSchema, AccountCustomDataSearchSchema } from "./account-custom-data";
import { EntrySchema } from "./entry";
import { customErrorMap, myPreProcess } from "../services/zodService";
import dayjs from "dayjs";


z.setErrorMap(customErrorMap);


export const AccountSchema = z.object({
    
    // required
    id: z.number(), // id
    business_id: z.number(), // business object id.
    account_type: z.enum(["לקוח-ספק","הכנסה","הוצאה","בנק","קופה","מערכת"]), // account type.
    card_number: z.number(), // the number of this account card.
    account_number: z.number(), // account number field.
    account_name: z.string(), // account name field.
    sort_code: z.number(), // sort-code index-table field.
    
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),


    // nullable
    info: z.string().nullable(), // more info field.
    identity_number: z.string().nullable(), // identify number field.
    type: z.number().nullable(), // type index-table field.
    address: z.string().nullable(), // address field.
    phone: z.string().nullable(), // phone number field.
    mobile: z.string().nullable(), // mobile number field.
    city: z.string().nullable(), // city field.
    email: z.string().nullable(), // email field.
    site: z.string().nullable(), // internet site address.
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullable()), // account include vat.
    payment_type: z.string().nullable(), // payment type for kupa account.
    

    //loads optional
    custom_data: z.array(AccountCustomDataSchema).optional(), // custom data added to account.
    entries: z.array(EntrySchema).optional(), // entries of this account.
    
});




export const AccountEditSchema = z.object({

    // required fields
    account_name: z.preprocess((val) => myPreProcess(val), z.string()), // account name field.
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // sort-code index-table field.


    // optional nullable fields
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // more info field.
    identity_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[0-9]*$/,"השדה יכול להכיל ספרות בלבד.").nullish()), // identify number field.
    type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // type index-table field.
    address: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // address field.
    phone: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()), // phone number field.
    mobile: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()), // mobile number field.
    city: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // city field.
    email: z.preprocess((val) => myPreProcess(val), z.string().email("אימייל לא תקין.").nullish()), // email field.
    site: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // internet site address.
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean({message: "יש לבחור ערך מהרשימה."}).nullish()), // account include vat.
    payment_type: z.string().nullish(), // payment type for kupa account.


    // loads optional
    custom_data: z.array(AccountCustomDataFormSchema).nullish(), // custom data added to account.
});


export const AccountAddSchema = z.object({

    // required
    account_type: z.enum(["לקוח-ספק","הכנסה","הוצאה","בנק","קופה","מערכת"]), // account type.
    card_number: z.number(), // the number of this account card.
    account_number: z.number(), // account number.


    // required fields
    account_name: z.preprocess((val) => myPreProcess(val), z.string()), // account name field.
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()), // sort-code index-table field.

    // optional nullable fields
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // more info field.
    identity_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[0-9]*$/,"השדה יכול להכיל ספרות בלבד.").nullish()), // identify number field.
    type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), // type index-table field.
    address: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // address field.
    phone: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()), // phone number field.
    mobile: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()), // mobile number field.
    city: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // city field.
    email: z.preprocess((val) => myPreProcess(val), z.string().email("אימייל לא תקין.").nullish()), // email field.
    site: z.preprocess((val) => myPreProcess(val), z.string().nullish()), // internet site address.
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean({message: "יש לבחור ערך מהרשימה."}).nullish()), // account include vat.
    payment_type: z.string().nullish(), // payment type for kupa account.


    // loads optional
    custom_data: z.array(AccountCustomDataFormSchema).nullish(), // custom data added to account.
});

export const AccountSearchSchema = z.object({
    
    id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_type: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    card_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    identity_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    address: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    phone: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    mobile: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    city: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    email: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    site: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
    payment_type: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

    created_at: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullish()),
    updated_at: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('HH:mm')), z.string().nullish()),

    //loads
    custom_data: z.array(AccountCustomDataSearchSchema).nullish(),

});



export type Account = z.infer<typeof AccountSchema>
export type AccountEdit = z.infer<typeof AccountEditSchema>
export type AccountAdd = z.infer<typeof AccountAddSchema>
export type AccountSearch = z.infer<typeof AccountSearchSchema>
