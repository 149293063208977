import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import dayjs from "dayjs";
import { AccountSchema } from "./account";
import { DocumentSchema } from "./document";


z.setErrorMap(customErrorMap);

export const PaymentSchema = z.object({

    //from server (optional)
    id: z.number(),
    business_id: z.number(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    document_id: z.number(),

    //inputs required
    total: z.number(),
    kupa_account_id: z.number(),
    // account_id: z.number(),

    //inputs optional
    bank_account_id: z.number().nullable(),
    bank_account_number: z.string().nullable(),
    bank_branch_number: z.string().nullable(),
    bank_name: z.string().nullable(),
    bank_number: z.string().nullable(),
    check_number: z.string().nullable(),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullable()),
    tax: z.string().nullable(),
    placed: z.string(),

    document: DocumentSchema.optional(),
    // document: DocumentSchema.optional(),
    kupa_account: AccountSchema.optional(),
});


export type Payment = z.infer<typeof PaymentSchema>
