import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
// import { createTheme, ThemeProvider } from '@mui/material';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Rtl from './components/Rtl/Rtl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './utils/AuthContext.tsx';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';


// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "rgb(64, 122, 214);",
//     },
//     // secondary: {
//     //   main: "#4782da"
//     // },
//     background: {
//       // main: "#1B2635"
//     }


//   },
//   typography: {
//     h1: {
//       fontSize: "3rem",
//       fontWeight: 600
//     },
//     h2: {
//       fontSize: "1.75rem",
//       fontWeight: 600
//     },
//     h3: {
//       fontSize: "1.5rem",
//       fontWeight: 600
//     },
//     //direction: 'rtl',
//   },


// }
// );



function FetchingError({ error }: FallbackProps) {


  return (
    <div role="alert">
      <p>התרחשה שגיאה</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <pre style={{ color: "red" }}>{error.code}</pre>
      
    </div>
  );
}




ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Rtl>
      <Provider store={store}>
        <AuthProvider>
          <ErrorBoundary
            fallbackRender={FetchingError}            
          >
            <App />
          </ErrorBoundary>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </AuthProvider>
      </Provider>
    </Rtl>
  </React.StrictMode>,
)
