import { InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { CssField } from "../../../../../components/mui-input/MuiInput.styled"
import { Controller, UseFormReturn } from "react-hook-form"
import { DocumentAdd } from "../../../../../models/document"
import { DocumentSettings } from "../../../../../models/document-settings"
import { DocumentStructure } from "../../../../../models/document-structure"
import MuiInput from "../../../../../components/mui-input/MuiInput"


interface HeaderProps {

    documentForm: UseFormReturn<DocumentAdd>;
    documentsSettings: DocumentSettings[];
    settings: DocumentSettings;
    structure: DocumentStructure[];
}


function Header({ documentForm, documentsSettings, settings, structure }: HeaderProps) {


    return (
        <div className="head">
            <div className="select-document">

                <CssField>
                    <InputLabel>סוג מסמך</InputLabel>

                    <Controller

                        name={"document_settings_id"}
                        control={documentForm.control}
                        defaultValue={settings.id}

                        render={({ field: { onChange, value } }) => (
                            <Select
                                value={value}
                                onChange={onChange}
                                id={"document_settings_id"}
                            >
                                {
                                    documentsSettings.map((documentSettings, index) => (
                                        <MenuItem key={index} value={documentSettings.id}>{documentSettings.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        )}
                    />
                </CssField>


            </div>
            <div className="document-number">
                <Typography variant="body1" component="div">
                    מספר {documentForm.getValues("document_number")}
                </Typography>
            </div>
            <div className="inputs">
                {structure?.map((field, index) => {
                    return field.type === "תאריך ושעה" ? <MuiInput key={`${field.field_name}${index}`} field={field} variant='outlined' useForm={documentForm} showRequired dateToNow /> : null
                })}
            </div>
        </div>
    )
}

export default Header