import { createContext, useState, useEffect, useContext, ReactNode } from "react";
//import { useNavigate } from "react-router-dom";
import { User } from "../models/user";
import { getUser, login, logout, register } from "../services/authService";
import { toast } from 'react-toastify';
import { toastErrorHandling } from "../services/errorHandling";
import Loading from "../pages/loading/Loading";
import { RegisterModel } from "../models/register";
import { useDispatch } from "react-redux";

interface AuthContextType {
    user: User | null;
    loginUser: (email: string, password: string) => Promise<void>;
    logoutUser: () => Promise<void>;
    registerUser: (registerInfo: RegisterModel) => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
    user: null,
    loginUser: async () => { },
    logoutUser: async () => { },
    registerUser: async () => { }
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [loading, setLoading] = useState<boolean>(true)
    const [user, setUser] = useState<User | null>(null)
    const dispatch = useDispatch();

    useEffect(() => {
        checkUserStatus();
    }, []);

    const loginUser = async (email: string, password: string): Promise<void> => {
        // setLoading(true);

        const loginPromise = async () => {
            const user = await login(email, password);
            setUser(user);
        }

        await toast.promise(
            loginPromise,
            {
                pending: 'מנסה להתחבר...',
                success: 'התחברת בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return toastErrorHandling(data)
                    }
                }
            }
        )
    }

    const logoutUser = async () => {
        // await account.deleteSession('current');
        await logout();
        dispatch({ type: 'logout' });

        setUser(null)
    }

    const registerUser = async (registerInfo: RegisterModel) => {
        // setLoading(true)

        // try {
        //     console.log('userInfo', userInfo)
        //     // let response = await account.create(ID.unique(), userInfo.email, userInfo.password1, userInfo.name);

        //     // await account.createEmailSession(userInfo.email, userInfo.password1)
        //     // let accountDetails = await account.get();
        //     // setUser(accountDetails)
        //     //navigate('/')
        // } catch (error) {
        //     console.error(error)
        // }

        const registerPromise = async () => {
            const user = await register(registerInfo);
            setUser(user);
        }

        await toast.promise(
            registerPromise,
            {
                pending: 'יוצר משתמש...',
                success: 'המשתמש נוצר בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return toastErrorHandling(data)
                    }
                }
            }
        )

        setLoading(false)
    }

    const checkUserStatus = async () => {
        try {
            const user = await getUser();
            setUser(user)
        } catch (_) {
        }
        setLoading(false)
    }

    const contextData: AuthContextType = {
        user,
        loginUser,
        logoutUser,
        registerUser
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? <Loading /> : children}
        </AuthContext.Provider>
    )
}

//Custom Hook
export const useAuth = () => { return useContext(AuthContext) }

export default AuthContext;