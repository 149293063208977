import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);


export const ItemEntrySchema = z.object({

    // required
    id: z.number(),
    business_id: z.number(),
    item_id: z.number(),
    sku: z.string().min(1),
    out: z.number(),
    in: z.number(),
    balance: z.number(),
    price: z.number(),
    account_name: z.string(),
    account_number: z.number(),

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()), 
    
});

export type ItemEntry = z.infer<typeof ItemEntrySchema>
