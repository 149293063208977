import { Typography } from "@mui/material"
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { groupBy } from "../../../../helpers/helpers";
import { useEffect } from "react";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { StyledAddCar } from "./AddWork.styled";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setWorksStructure } from "../../../../redux/features/structure";
import { UseFormReturn } from "react-hook-form";
import { WorkAdd } from "../../../../models/work";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../../services/errorHandling";

interface AddWorkProps {
  setStructureIsLoading: (isLoading: boolean) => void;
  useForm: UseFormReturn<WorkAdd>
}

function AddWork({ setStructureIsLoading, useForm }: AddWorkProps) {

  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch()

  
  const workStructure = useSelector((state: RootState) => state.structure.works);
  const structureSortedByTitle = groupBy(workStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure=> !!structure.show_form === true);


  useEffect(() => {
    if (!workStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);


  async function getStructureAndSaveAtStore() {
    try {
      setStructureIsLoading(true);

      const structure = await getAccountsStructure({
        account_type: "עבודה"
      });

      dispatch(setWorksStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
    setStructureIsLoading(false);
  }



  return (
    <StyledAddCar>
      {workStructure &&
        <>
          <div>
            <Typography variant="body1">פרטי עבודה</Typography>
            <div className="fields">
              {structureSortedByTitle?.["פרטים"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            <Typography variant="body1">יצרן ודגם</Typography>
            <div className="fields">
              { structureSortedByTitle?.["יצרן ודגם"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            <Typography variant="body1">הכנסות והוצאות</Typography>
            <div className="fields">
              { structureSortedByTitle?.["הכנסות והוצאות"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
            <div className="fields">
              { structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} /> : null
              })}
            </div>
          </div>

        </>}

    </StyledAddCar>
  )
}

export default AddWork