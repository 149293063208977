import { z } from "zod";
import { CarCustomDataFormSchema, CarCustomDataSchema } from "./car-custom-data";
import { customErrorMap, myPreProcess } from './../services/zodService';
import { AccountSchema } from "./account";

z.setErrorMap(customErrorMap);


export const CarSchema = z.object({

    // required
    id: z.number(), // id
    business_id: z.number(), // business object id.
    license_number: z.string(), // license number.

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),


    // nullable
    account_number: z.number().nullable(), // account number (customer) field
    info: z.string().nullable(), // more info field.
    manufacturer: z.number().nullable(), // manufacturer table-index field.
    model: z.number().nullable(), // model table-index field.
    production_year: z.number().nullable(), // production year field.
    
    //loads
    custom_data: z.array(CarCustomDataSchema).nullish(),
    customer: AccountSchema.nullish(),
});


export const CarEditSchema = z.object({

    // optional nullable fields
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    //loads
    custom_data: z.array(CarCustomDataFormSchema).nullish(),
});

export const CarAddSchema = z.object({

    // required fields
    license_number: z.preprocess((val) => myPreProcess(val), z.string().min(7,"השדה חייב להכיל לפחות 7 תווים"),), 

    // optional nullable fields
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    //loads
    custom_data: z.array(CarCustomDataFormSchema).nullish(),
});


export const CarSearchSchema  = z.object({

    license_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
    //loads
    custom_data: z.array(CarCustomDataSchema).nullish(),
});

const PartialCarSchema = CarSchema.partial();


export type Car = z.infer<typeof CarSchema>
export type PartialCar = z.infer<typeof PartialCarSchema>
export type CarEdit = z.infer<typeof CarEditSchema>
export type CarAdd = z.infer<typeof CarAddSchema>
export type CarSearch = z.infer<typeof CarSearchSchema>

