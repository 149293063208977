import axiosClient from "../axios-client";
import { DesignSettings } from "../models/design-settings";

export const getDesignSettings = async (sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', body: object = {}, signal: AbortSignal): Promise< DesignSettings[]> => {
    const response = await axiosClient.post(`/api/design-settings/search/${maxRecords}/${sortKey}/${sortMethod}`, body, {
        signal: signal // Pass the signal to the request options
    });
    return response.data;
}


export const saveDesignSettings = async (designSettingsId: number, designSettingsToSave: DesignSettings): Promise<DesignSettings> => {
    const response = await axiosClient.patch(`/api/design-settings/${designSettingsId}`, designSettingsToSave);
    return response.data;
}