import { Typography } from "@mui/material"
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { groupBy } from "../../../../helpers/helpers";
import { useEffect } from "react";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { UseFormReturn } from "react-hook-form";
import { StyledAddSystem } from "./AddSystem.styled";
import { setSystemStructure } from "../../../../redux/features/structure";
import { AccountAdd } from "../../../../models/account";
import { useErrorBoundary } from "react-error-boundary";
import { handleApiErrors } from "../../../../services/errorHandling";


interface AddSystemProps {
  setStructureIsLoading: (isLoading: boolean) => void;
  useForm: UseFormReturn<AccountAdd>
}


function AddSystem({ setStructureIsLoading, useForm }: AddSystemProps) {

  
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch()

  const systemStructure = useSelector((state: RootState) => state.structure.system);
  const structureSortedByTitle = groupBy(systemStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure=> !!structure.show_form === true);


  useEffect(() => {
    if (!systemStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);


  async function getStructureAndSaveAtStore() {
    try {
      setStructureIsLoading(true);

      const structure = await getAccountsStructure({
        account_type: "מערכת"
      });

      dispatch(setSystemStructure(structure || []));

    } catch (error) {
      handleApiErrors(error, showBoundary);
    }
    setStructureIsLoading(false);
  }



  return (
    <StyledAddSystem>
      {systemStructure &&
        <>
          <div>
            <Typography variant="body1">פרטים</Typography>
            <div className="fields">
              {structureSortedByTitle?.["פרטים"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
            <div className="fields">
              { structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

        </>}

    </StyledAddSystem>
  )
}

export default AddSystem