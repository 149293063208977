// import { Account, PartialAccount } from "../models/account";
// import { AccountCustomData } from "../models/account-custom-data";

// type customData = {
//     field_index: number,
//     field_value: string | null
// }

// export const setCustomData = <T extends customData>(customDataArray: T[] | undefined, accountId?: number | null | undefined) => {
//     const customDataArrayWithNames = customDataArray?.map((customDataObj, index) => {
//         // if (accountId) {
//         //     return {
//         //         ...customDataObj,
//         //         account_id: accountId,
//         //         field_name: `custom_data.${index}.field_value`,
//         //         field_value: customDataObj.field_value
//         //     }
//         // }

//         // else {
//         //     return {
//         //         ...customDataObj,
//         //         field_name: `custom_data.${index}.field_value`,
//         //         field_value: customDataObj.field_value
//         //     }
//         // }
//         if (accountId) {
//             return {
//                 ...customDataObj,
//                 account_id: accountId,
//                 field_index: index,
//                 field_value: customDataObj.field_value
//             }
//         }

//         else {
//             return {
//                 ...customDataObj,
//                 field_index: index,
//                 field_value: customDataObj.field_value
//             }
//         }
//     })
//     return customDataArrayWithNames || [];
// }


export const setCustomDataArray = <T extends {field_index: number, field_value: string | null}> (customDataArray: T[] | undefined | null) => {

    if (!customDataArray) {
        return;
    }

    for (let i = 0; i < customDataArray.length; i++) {
        if (!(i in customDataArray)) {
            customDataArray[i] = {
                ...customDataArray[i],
                field_index: i,
                field_value: null
            }; // Fill empty slots with an empty object
        }
        else{
            customDataArray[i] = {
                ...customDataArray[i],
                field_index: i,
            };
        }
    }
}

// export const addCustomDataNamesToAccount = <T extends { custom_data?: customData[] | undefined, id?: number | null | undefined }>(account: T): T => {
//     const accountCopy = { ...account };

//     if ("custom_data" in accountCopy) {
//         accountCopy.custom_data = setCustomData(accountCopy.custom_data, account.id);
//     }

//     return accountCopy;
// }