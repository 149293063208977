import { z } from "zod";
import { myPreProcess } from "../services/zodService";


export const UserSchema = z.object({

    // required
    id: z.number().optional(),
    business_id: z.number(),
    role: z.string(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    password: z.string().optional(),

    created_at: z.date().optional(),
    updated_at: z.date().optional(),


    // nullable
    email_verified_at: z.date().nullable(),
    remember_token: z.number().nullable(),

});

export const UserAddSchema = z.object({

    // required
    
    role: z.string(),
    email: z.preprocess((val) => myPreProcess(val), z.string().email("אימייל אינו תקין")), 
    first_name: z.preprocess((val) => myPreProcess(val), z.string().max(20)), 
    last_name: z.preprocess((val) => myPreProcess(val), z.string().max(20)), 
    password: z.preprocess((val) => myPreProcess(val), z.string()), 

});

export type User = z.infer<typeof UserSchema>
export type UserAdd = z.infer<typeof UserAddSchema>
