import axios from 'axios'

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,

});

axiosClient.interceptors.request.use(
  function (config) {
    // Decode CSRF token from cookies
    const xsrfCookie = document.cookie
      .split('; ')
      .find(cookie => cookie.startsWith('XSRF-TOKEN'));

    // Extract token value if the cookie is found
    if (xsrfCookie) {
      const decodedCsrfToken = decodeURIComponent(xsrfCookie.split('=')[1]);
      // Add CSRF token to headers
      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      config.headers['X-XSRF-TOKEN'] = decodedCsrfToken;
      
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);


// Add a response interceptor
axiosClient.interceptors.response.use(
  response => response, // Pass through successful responses
  async error => {
    // Check if the error is due to a session expiration (status 419)
    if (error.response && error.response.status === 419) {
      console.warn('Session expired. Attempting to refresh session...');

      // Attempt to refresh the session
      const sessionRefreshed = await refreshSession();

      if (sessionRefreshed) {
        // Retry the original request with the updated token
        return axiosClient.request(error.config); // Retry the original request
      }
    }

    // If session refresh fails or it's a different error, reject the promise
    return Promise.reject(error);
  }
);


// Helper function to refresh the session
const refreshSession = async () => {
  try {

    await axiosClient.get('/');
    return true; // Indicate success
  } catch (error) {
    console.error('Failed to refresh session:', error);
    return false; // Indicate failure
  }
};

export default axiosClient;