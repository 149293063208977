import axios from "axios";

export const toastErrorHandling = (error: unknown, page?: string): string => {

    console.log("toastErrorHandling");

    if (axios.isAxiosError(error)) {
        if (error.response?.data.message === "These credentials do not match our records.") // status_code 422
        {
            return "פרטי ההתחברות אינם נכונים"
        }
        if (error.response?.data.error === "The account number does not exist.") {
            return "מספר חשבון לקוח אינו קיים"
        }
        if (error.response?.data.error === "Unique constraint violation.") {

            if(page === "add account"){
                return "קיים כרטיס עם מספר חשבון זה"
            }
            if(page === "add car"){
                return "קיים רכב עם מספר חשבון זה"
            }
            if(page === "add work"){
                return "קיים כרטיס עבודה עם מספר חשבון זה"
            }
            if(page === "add item"){
                return "קיים פריט עם מספר חשבון זה"
            }

            return "חשבון זה כבר קיים";
        }
        
        // if (error.response?.data.error === "Unique constraint violation.") {
        //     return "מספר חשבון לקוח אינו קיים."
        // }
        

        // if (error.response?.data.error === "Unique constraint violation.") {
        //     return "Unique constraint violation."
        // }
        console.log("errorHandling error", error)
        console.log("errorHandling status", error.status)
        console.log("errorHandling response", error.response);
        console.log("errorHandling response data m", error.response?.data.message);
        // Do something with this error...
        return error.message;

    } else {
        console.error(error);
    }

    return "התרחשה שגיאה"
}

// handle if to show error or not
export const handleApiErrors = (error: unknown, showBoundary: (error: any) => void): void => {
    
    if (axios.isAxiosError(error)) {
        if (error.code === "ERR_CANCELED") {
            return;
        }
        
    }


    showBoundary(error);
    
}
