import { IconButton, Typography } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip } from "@mui/material";
import { useTheme } from '@emotion/react';
import { UseFormReturn } from 'react-hook-form';
import { DocumentAdd } from '../../../../../models/document';


interface TitleProps{
    
    documentForm: UseFormReturn<DocumentAdd>;
    
    browseDocument: () => void;
    browseReference: () => void;
    editDocumentSettings: () => void;

}

function Title({documentForm, browseDocument, browseReference, editDocumentSettings}: TitleProps) {

    const theme = useTheme();

    return (
        <div className="title">
            <div>
                <Typography variant="h5" component={'span'} >
                    הוספת מסמך
                </Typography>
                <Tooltip title="טעינה של מסמך קיים מתוך דפדוף">
                    <IconButton onClick={browseDocument} sx={{ color: theme.colors.main_color }}>
                        <AutoStoriesIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="צירוף אסמכתא">
                    <IconButton onClick={browseReference} sx={{ color: theme.colors.main_color }}>
                        <FilePresentIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="הגדרות מסמך">
                    <IconButton onClick={editDocumentSettings} sx={{ color: theme.colors.main_color }}>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
                {/* <button onClick={documentSettings}>הגדרות מסמך</button> */}
            </div>
            <div>
                {/* אסמכתא: כרטיס עבודה 1 */}
                {documentForm.getValues("reference") ? "אסמכתא: " + documentForm.getValues("reference") : ""}
            </div>

        </div>
    )
}

export default Title