import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectTable, Table } from '../../models/table';
import { CustomTableItem } from '../../models/custom-table-item';
import { ManufacturerModel } from '../../models/manufacturer-model';

export interface InitialState {
  manufacturersTable: Table[] | undefined; //table 1
  warehousesTable: Table[] | undefined; //table 2
  tagsTable: Table[] | undefined; //table 3
  sortCodesTable: Table[] | undefined; //table 4
  itemGroupsTable: Table[] | undefined; //table 5
  banksTable: SelectTable[] | undefined; //table 6
  systemTable: SelectTable[] | undefined; 
  modelsTable: ManufacturerModel[] | undefined;

  customTables: Table[] | undefined;
  customTablesItems: CustomTableItem[] | undefined;

  incomesTable: SelectTable[] | undefined;
  outcomesTable: SelectTable[] | undefined;
  kupaTable: SelectTable[] | undefined;
  banksAccountTable: SelectTable[] | undefined;

}

const initialState: InitialState = {
  manufacturersTable: undefined,
  warehousesTable: undefined,
  tagsTable: undefined,
  sortCodesTable: undefined,
  itemGroupsTable: undefined,
  banksTable: undefined,
  systemTable: undefined,
  modelsTable: undefined,

  customTables: undefined,
  customTablesItems: undefined,

  incomesTable: undefined,
  outcomesTable: undefined,
  kupaTable: undefined,
  banksAccountTable: undefined,

}

export const tables = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setManufacturersTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.manufacturersTable = action.payload
    },
    setWarehousesTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.warehousesTable = action.payload
    },
    setTagsTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.tagsTable = action.payload
    },
    setSortCodesTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.sortCodesTable = action.payload
    },
    setItemGroupsTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.itemGroupsTable = action.payload
    },
    setBanksTable: (state, action: PayloadAction<SelectTable[] | undefined>) => {
      state.banksTable = action.payload
    },
    setSystemTable: (state, action: PayloadAction<Table[] | undefined>) => {
      state.systemTable = action.payload
    },
    setModelsTable: (state, action: PayloadAction<ManufacturerModel[] | undefined>) => {
      state.modelsTable = action.payload
    },

    setCustomTables: (state, action: PayloadAction<Table[] | undefined>) => {
      state.customTables = action.payload
    },
    setCustomTablesItems: (state, action: PayloadAction<CustomTableItem[] | undefined>) => {
      state.customTablesItems = action.payload
    },

    setIncomesTable: (state, action: PayloadAction<SelectTable[] | undefined>) => {
      state.incomesTable = action.payload
    },
    setOutcomesTable: (state, action: PayloadAction<SelectTable[] | undefined>) => {
      state.outcomesTable = action.payload
    },
    setKupaTable: (state, action: PayloadAction<SelectTable[] | undefined>) => {
      state.kupaTable = action.payload
    },
    setBanksAccountTable: (state, action: PayloadAction<SelectTable[] | undefined>) => {
      state.banksAccountTable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase('logout', () => initialState); // Reset to initial state on logout
  },
})


// Action creators are generated for each case reducer function
export const {

  setManufacturersTable,
  setWarehousesTable,
  setTagsTable,
  setSortCodesTable,
  setItemGroupsTable,
  setBanksTable,
  setSystemTable,
  setModelsTable,

  setCustomTables,
  setCustomTablesItems,

  setIncomesTable,
  setOutcomesTable,
  setKupaTable,
  setBanksAccountTable,

} = tables.actions

export default tables.reducer