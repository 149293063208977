import axiosClient from "../axios-client";
import { DocumentStructure, DocumentStructureAdd, DocumentStructureEdit } from "../models/document-structure";

export const getDocumentsStructure = async (body = {}): Promise<DocumentStructure[]> => {
    const response = await axiosClient.post(`/api/documents-structure/search`, body);
    return response.data;
}


export const editDocumentStructure = async (documentStructureToUpdate: DocumentStructureEdit): Promise<DocumentStructure> => {
    const response = await axiosClient.patch(`api/documents-structure/${documentStructureToUpdate.id}`, documentStructureToUpdate);
    return response.data;
}

export const addCustomDocumentStructure = async (body: DocumentStructureAdd): Promise<DocumentStructure> => {
    const response = await axiosClient.post(`api/documents-structure`, body);
    return response.data;
}