import { useTheme } from "@emotion/react";
import { IconButton, Tooltip, Typography } from "@mui/material"
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { DocumentWorkAdd } from "../../../../../models/document-work";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import { DocumentStructure } from "../../../../../models/document-structure";
import MuiInput from "../../../../../components/mui-input/MuiInput";

interface worksProps {

    worksForm: UseFormReturn<{ works: DocumentWorkAdd[] }>;
    worksFieldArray: UseFieldArrayReturn<{ works: DocumentWorkAdd[] }, "works", "id">;

    structure: DocumentStructure[];
    workRowIndex: number;

    browseWork: () => void;
    addWork: () => void;
    editWork: () => void;

    setWorkRowIndex: (rowIndex: number) => void;
    addWorkLine: () => void;
    deleteRow: (rowIndex: number, type: string) => void;
    editWorkStructure: () => void;

    isFocused: (baseName: string, inputName: string) => boolean;
}


function Works({ worksForm, worksFieldArray, structure, workRowIndex, browseWork, addWork, editWork, setWorkRowIndex, addWorkLine, deleteRow, editWorkStructure, isFocused }: worksProps) {

    const theme = useTheme();

    return (
        <div className="works">
            <div className="title">
                <div>
                    <Typography variant="body1" component="span">
                        עבודות:
                    </Typography>
                    <Tooltip title="בחירת כרטיס עבודה מתוך דפדוף" placement="top">
                        <IconButton onClick={browseWork} sx={{ color: theme.colors.main_color }}>
                            <AutoStoriesIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="הוספת כרטיס עבודה" placement="top">
                        <span>
                            <IconButton onClick={addWork} disabled={!!worksForm.getValues().works?.[workRowIndex]?.id} sx={{ color: theme.colors.main_color }}>
                                <BookmarkAddIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="עריכת כרטיס עבודה" placement="top">
                        <span>
                            <IconButton onClick={editWork} disabled={!worksForm.getValues().works?.[workRowIndex]?.id} sx={{ color: theme.colors.main_color }}>
                                <EditIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title="הגדרת שדות עבודה" placement="top">
                        <span>
                            <IconButton onClick={editWorkStructure} sx={{ color: theme.colors.main_color }}>
                                <SettingsIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {/* <button onClick={editWorkStructure}>הגדרת שדות</button> */}
                </div>
            </div>
            <table>
                <tbody>
                    {
                        worksFieldArray.fields.map((field, rowIndex) => (

                            <tr key={field.id} onClick={() => { setWorkRowIndex(rowIndex) }}>
                                <td className="row-number">{rowIndex + 1}.</td>
                                {
                                    structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                        return (field.type === "עבודה" && field.show_form) ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={worksForm} prefix={`works.${rowIndex}.`} showRequired autoFocus={isFocused(`works.${rowIndex}.`, field.field_name)} /></td> : null
                                    })
                                }
                                <td><button onClick={() => { deleteRow(rowIndex, 'work') }}>X</button></td>
                            </tr>

                        ))
                    }
                </tbody>
            </table>
            <button onClick={addWorkLine}>הוספת שורה</button>
        </div>)
}

export default Works