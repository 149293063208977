import { DocumentStructure } from "../models/document-structure";

export const demoEntriesDataTable = [
    {
        "id": 1,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 2,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 3,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 4,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 5,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 6,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 7,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 8,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 9,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 10,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 11,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 12,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 13,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 14,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 15,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 150,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 16,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס קבלה",
        "debit": 117,
        "credit": null,
        "balance": 117,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    },
    {
        "id": 17,
        "business_id": 5,
        "account_id": 8,
        "document_id": 2,
        "entry_name": "חשבונית מס",
        "debit": 117,
        "credit": null,
        "balance": 120,
        "counter_account": "12010",
        "doc_name_2": "",
        "doc_num_2": null,
        "ref_2": "",
        "value": "2024-05-28 07:02:34",
        "info": "פרטים",
        "created_at": "2024-05-28T07:02:34.000000Z",
        "updated_at": "2024-05-28T07:02:34.000000Z",
    }
];

export const paymentFieldsStructure: DocumentStructure[] = [
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "total",
        "field_heb_name": "סכום",
        "field_display_name": "סכום",
        "field_length": 5,
        "field_type": 7,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "kupa_account_id",
        "field_heb_name": "תשלום",
        "field_display_name": "תשלום",
        "field_length": 5,
        "field_type": 4,
        "field_table": 15,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_account_id",
        "field_heb_name": "העברה לבנק",
        "field_display_name": "העברה לבנק",
        "field_length": 5,
        "field_type": 4,
        "field_table": 14,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_account_number",
        "field_heb_name": "מס חשבון",
        "field_display_name": "מס חשבון",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_branch_number",
        "field_heb_name": "סניף",
        "field_display_name": "סניף",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_name",
        "field_heb_name": "שם בנק",
        "field_display_name": "שם בנק",
        "field_length": 5,
        "field_type": 5,
        "field_table": 6,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_number",
        "field_heb_name": "מספר בנק",
        "field_display_name": "מספר בנק",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "check_number",
        "field_heb_name": "מספר שיק",
        "field_display_name": "מספר שיק",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "to_date",
        "field_heb_name": "לתאריך",
        "field_display_name": "לתאריך",
        "field_length": 5,
        "field_type": 3,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "tax",
        "field_heb_name": "מס במקור",
        "field_display_name": "מס במקור",
        "field_length": 5,
        "field_type": 1,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
];


export const depositFieldsStructure: DocumentStructure[] = [
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "total",
        "field_heb_name": "סכום",
        "field_display_name": "סכום",
        "field_length": 5,
        "field_type": 7,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "kupa_account_id",
        "field_heb_name": "תשלום",
        "field_display_name": "תשלום",
        "field_length": 5,
        "field_type": 4,
        "field_table": 15,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_account_number",
        "field_heb_name": "מס חשבון",
        "field_display_name": "מס חשבון",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_branch_number",
        "field_heb_name": "סניף",
        "field_display_name": "סניף",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_name",
        "field_heb_name": "שם בנק",
        "field_display_name": "שם בנק",
        "field_length": 5,
        "field_type": 5,
        "field_table": 6,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "bank_number",
        "field_heb_name": "מספר בנק",
        "field_display_name": "מספר בנק",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "check_number",
        "field_heb_name": "מספר שיק",
        "field_display_name": "מספר שיק",
        "field_length": 5,
        "field_type": 2,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
    {
        "id": 2,
        "business_id": 5,
        "type": "תשלום",
        "show_form_array": [],
        "show_form": true,
        "required": false,
        "show_search": false,
        "show_browse": false,
        "editable": true,
        "field_name": "to_date",
        "field_heb_name": "לתאריך",
        "field_display_name": "לתאריך",
        "field_length": 5,
        "field_type": 3,
        "field_table": 0,
        "custom_field": null,
        "location": 1,
        "to_print": false,
        "created_at": new Date(),
        "updated_at": new Date()
    },
];